<div class="modal-content">
  <ngx-ui-loader></ngx-ui-loader>
  <form *ngIf="meetingForm" [formGroup]="meetingForm" (ngSubmit)="onSubmit()"
    class="form fv-plugins-bootstrap5 fv-plugins-framework" id="kt_modal_add_event_form">
    <div class="modal-header">
      <h2 class="fw-bolder" data-kt-calendar="title">Schedule Meeting</h2>
      <div class="btn btn-icon btn-sm btn-active-icon-primary" id="kt_modal_add_event_close">
        <span class="svg-icon svg-icon-1" (click)="close()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
              fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor">
            </rect>
          </svg>
        </span>
      </div>
    </div>
    <div class="modal-body py-10 px-lg-12">
      <ng-container *ngIf="calenderAvilablity?.availabilityHours !== METTING_AVAILABILITY.temporary_unavailable">



        <div class="col-12" *ngIf="selectedMeetingWithUser || !modalData.otherUser">
          <div class="fv-row mb-9">
            <label class="fs-6 fw-bold mb-2">Who would you prefer to schedule a meeting with?</label>

            <ng-select [items]="reviewers" [multiple]="false" placeholder="Select a name"
              [ngModelOptions]="{standalone: true}" [clearable]="false" (change)="handleMeetingWithChange($event)"
              [(ngModel)]="selectedMeetingWithUser" [bindLabel]="'label'" class="meeting-time-select" appendTo="body">
              <!-- <ng-template ng-label-tmp let-item="item">
                <b>{{item.label}}</b>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <b>{{item.label}}</b>
              </ng-template> -->



              <ng-template ng-label-tmp let-item="item">

                <p class="mb-0">{{item.label}} ({{ accountTypelabelMap[item?.accountType] || item.accountType }})</p>



              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="d-flex align-items-center">
                  <p class="mb-0">{{item.label}}</p>


                  <div class="badge badge-light bg-white text-uppercase fw-bolder ms-2 d-none d-md-inline-block">
                    {{ item?.accountType | accountTypeToLabel }}
                  </div>
                </div>

              </ng-template>


            </ng-select>
          </div>
        </div>


        <div class="row">
          <div class="col-6">

            <div class="fv-row mb-9 fv-plugins-icon-container">
              <label class="fs-6 fw-bold required mb-2">Meeting Title</label>
              <input type="text" formControlName="meetingTitle" class="form-control form-control-solid" placeholder=""
                name="calendar_event_name" />
              <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>

            <div class="fv-row fv-plugins-icon-container">
              <label class="fs-6 fw-bold mb-2 required">Agenda</label>
              <textarea formControlName="meetingDescription" class="form-control form-control-solid" placeholder=""
                name="calendar_event_name" rows="7"></textarea>

              <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>
          </div>
          
          <div class="col-6">
            <div class="row mb-8">
              <div class="col-12">
                <label class="fs-6 fw-bold required mb-2 d-block">Duration (mins)</label>
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    *ngFor="let item of durationOptions"
                    type="button"
                    class="btn btn-sm"
                    [ngClass]="
                      meetingForm.value.duration === item
                        ? 'btn-dark text-white'
                        : 'btn-outline  btn-outline-default'
                    "
                    (click)="onSelectDuration(item)"
                  >
                    {{item}}
                  </button>
                </div>
              </div>
            </div>

            <div class="row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">

              <div class="col-6">
                <label class="fs-6 fw-bold mb-2 required">Date</label>

                <input class="form-control" formControlName="date" class="form-control form-control-solid flatpickr-input"
                  placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker"
                  (click)="d.toggle()" [minDate]="minDate" [markDisabled]="isDisabled" />

                <div class="fv-plugins-message-container invalid-feedback"></div>
              </div>


              <!-- <div class="col-6">

                <div class="fv-row mb-9">
                  <label class="fs-6 fw-bold mb-2">Start Time</label>
                  <input formControlName="timeFrom" class="form-control form-control-solid flatpickr-input"
                    name="calendar_event_start_time" placeholder="Pick a start time" id="kt_calendar_datepicker_start_time"
                    type="time" (ngModelChange)="handleDurationChange()">
                </div>
              </div> -->


              <div class="col-6">
                <div class="fv-row mb-3">
                  <label class="fs-6 fw-bold mb-2">Start Time</label>

                  <!-- <select formControlName="timeFrom" class="form-control form-control-solid"
                                (ngModelChange)="handleDurationChange()">
                                <option value="">Select time</option>
                                <option *ngFor="let t of timeSlots" value="{{t}}">{{t | twentyFourHoursToAmPm}}</option>
                              </select> -->


                  <ng-select [items]="timeSlots" formControlName="timeFrom" [multiple]="false" placeholder="Select Time"
                    (change)="handleDurationChange()" [bindLabel]="'label'" [bindValue]="'value'"
                    class="meeting-time-select" appendTo="body">
                    <ng-template ng-label-tmp let-item="item">
                      <b>{{item.label}}</b>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <b>{{item.label}}</b>
                    </ng-template>
                  </ng-select>
                </div>
              </div>


            </div>




            <!-- <div class="row mb-8">
              <div class="col-12">
                <label class="fs-6 fw-bold required mb-2 d-block">Meeting Tool</label>
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    *ngFor="let item of meetingToolTypes"
                    type="button"
                    class="btn"
                    [ngClass]="
                      meetingForm.value.meetingToolType === item
                        ? 'btn-dark text-white'
                        : 'btn-outline  btn-outline-default'
                    "
                    (click)="onSelectMeetingTool(item)"
                  >
                    {{item | titlecase}}
                  </button>
                </div>
              </div>
            </div> -->



            <div class="row mb-5 mt-6">
              <div class="col-12">
                <label class="fs-6 fw-bold required mb-2 d-block">Mode of Meeting</label>
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    *ngFor="let item of modeOfMeetings"
                    type="button"
                    class="btn btn-sm"
                    [ngClass]="
                      meetingForm.value.meetingLocationType === item.value
                        ? 'btn-dark text-white'
                        : 'btn-outline  btn-outline-default'
                    "
                    (click)="onSelectMeetingMode(item)"
                  >
                    {{item.label | titlecase}}
                  </button>
                </div>
              </div>
            </div>


            <div class="fv-row  fv-plugins-icon-container" *ngIf="meetingForm.value.meetingLocationType === MeetingLocationTypes.INPERSON">
              <label class="fs-6 fw-bold mb-2 required">Venue</label>
              <input type="text" formControlName="meetingInPersonLocation" class="form-control form-control-solid"
                placeholder="e.g., CCD, Sector 2, Noida"
                />
              <div class="fv-plugins-message-container invalid-feedback">
                <span class="invalid-feedback d-block ms-1" *ngIf="
                meetingForm.get('meetingInPersonLocation')?.hasError('required') &&
                (meetingForm.get('meetingInPersonLocation')?.touched ||
                meetingForm.get('meetingInPersonLocation')?.dirty)
              ">
              Meeting location is required
            </span>


              </div>
            </div>

            <ng-container *ngIf="meetingForm.value.meetingLocationType === MeetingLocationTypes.VIRTUAL">
              <div class="mb-4">

                <!-- <div class="alert alert-info rounded fs-7 text-center py-3 flex flex-column mb-2 ">
                  <p class="mb-0 d-flex align-items-center">
                    <i class="bi bi-info-circle fw-boldest me-2" style="color: #004085;"></i>

                    Default: Inbuilt Meeting Tool
                  </p>
                </div>


                <div
                class="form-check form-switch form-check-custom form-check-solid"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchDefault-useExternalTool"
                    formControlName="useExternalTool"
                    (change)="onSelectMeetingTool($event)"
                    style="height: 1.75rem !important;"
                  />
                  <label class="form-check-label fs-7" for="flexSwitchDefault-useExternalTool">
                    Use external meeting URL (e.g Google Meet, Zoom etc)
                  </label>
                </div> -->

                <div
                class="form-check form-check-custom form-check-solid"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="meetingTool"
                    [value]="MeetingToolTypes.INBUILT"
                    formControlName="meetingToolType"
                    style="height: 1.75rem !important;"
                    id="flexSwitchDefault-useExternalTool-inbuilt"
                    />
                    <!-- (change)="onSelectMeetingTool($event)" -->
                  <label class="form-check-label fs-7" for="flexSwitchDefault-useExternalTool-inbuilt">
                    In-built Meeting Tool
                  </label>
                </div>

                <div
                class="form-check form-check-custom form-check-solid mt-1"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="meetingTool"
                    [value]="MeetingToolTypes.EXTERNAL"
                    formControlName="meetingToolType"
                    style="height: 1.75rem !important;"
                    id="flexSwitchDefault-useExternalTool-ext"
                    />
                    <!-- (change)="onSelectMeetingTool($event)" -->
                  <label class="form-check-label fs-7" for="flexSwitchDefault-useExternalTool-ext">
                    External Meeting URL
                  </label>
                </div>

              </div>





              <div class="fv-row mb-9 fv-plugins-icon-container" *ngIf="meetingForm.value.meetingToolType === MeetingToolTypes.EXTERNAL">
                <!-- <label class="fs-6 fw-bold required mb-2">Enter URL</label> -->
                <input type="text" formControlName="meetingExternalUrl" class="form-control form-control-solid"
                  name="calendar_event_name"
                  placeholder="e.g., e.g Google Meet https://meet.google.com/***-nqme-***"
                  />
                <div class="fv-plugins-message-container invalid-feedback">
                  <span class="invalid-feedback d-block ms-1" *ngIf="
                      meetingForm.get('meetingExternalUrl')?.hasError('required') &&
                      (meetingForm.get('meetingExternalUrl')?.touched ||
                      meetingForm.get('meetingExternalUrl')?.dirty)
                    ">
                    Meeting url is required
                  </span>

                  <span
                      class="invalid-feedback d-block ms-1"
                      *ngIf="
                      meetingForm
                          .get('meetingExternalUrl')
                          ?.hasError('pattern') &&
                        (meetingForm.get('meetingExternalUrl')?.touched ||
                        meetingForm.get('meetingExternalUrl')?.dirty)
                      "
                    >
                      Invalid meeting Url
                    </span>

                </div>
              </div>




            </ng-container>






          </div>
        </div>






        <!-- <div class="fv-row mb-5 fv-plugins-icon-container">
          <label class="fs-6 fw-bold required mb-2">Duration</label>
          <select formControlName="duration" class="form-control form-control-solid"
            (ngModelChange)="handleDurationChange()">
            <option value="">Select duration</option>
            <option value="15">15 minutes</option>
            <option value="30">30 minutes</option>
            <option value="45">45 minutes</option>
            <option value="60">60 minutes</option>
          </select>
          <div class="fv-plugins-message-container invalid-feedback"></div>
        </div> -->
        <!-- <input type="text" formControlName="duration" class="form-control form-control-solid" placeholder=""
        name="calendar_event_name" (ngModelChange)="handleDurationChange($event)" /> -->
      </ng-container>
      <ng-container *ngIf="calenderAvilablity?.availabilityHours === METTING_AVAILABILITY.temporary_unavailable">
        <p class="text-center">
          The other party has temporary turned off meetings in their account.
        </p>
      </ng-container>

    </div>
    <div class="modal-footer flex-center">
      <button type="reset" id="kt_modal_add_event_cancel" (click)="close()"
        class="btn btn-light ls-2 text-uppercase fw-boldest me-3">
        Cancel
      </button>
      <ng-container *ngIf="calenderAvilablity?.availabilityHours !== METTING_AVAILABILITY.temporary_unavailable">
        <button type="submit" [disabled]="meetingForm.invalid" id="kt_modal_add_event_submit"
          class="btn btn-theme text-white ls-2 text-uppercase fw-boldest">
          <span class="indicator-label">Submit</span>
          <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
        </button>
      </ng-container>
    </div>
    <div></div>
  </form>
</div>
