<div class="modal-header">
  <h4 class="modal-title">Let's connect</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
  <div>
    <div class="w-100">

      <ul class="list-group  rounded" *ngIf="profileData.accountType !== ACCOUNT_TYPE.STARTUP">
        <li class="list-group-item w-100 py-4" *ngFor="let option of acceptOptions">
          <div class="form-check form-check-custom form-check-solid form-check-sm">
            <input class="form-check-input" type="radio" [value]="option.value" id="{{option.value}}" name='message'
              [(ngModel)]='messageAcceptOptions' (ngModelChange)="handleChangeOp($event)" />
            <label class="form-check-label " style="text-align: start;" for="{{option.value}}">
              {{option.label || option.value}}
            </label>
          </div>
        </li>
      </ul>

      <ul class="list-group  rounded" *ngIf="profileData.accountType === ACCOUNT_TYPE.STARTUP">
        <li class="list-group-item w-100 py-4" *ngFor="let option of startUpAcceptOptions">
          <div class="form-check form-check-custom form-check-solid form-check-sm">
            <input class="form-check-input" type="radio" [value]="option.value" id="{{option.value}}" name='message'
              [(ngModel)]='messageAcceptOptions' (ngModelChange)="handleChangeOp($event)"/>
            <label class="form-check-label " style="text-align: start;" for="{{option.value}}">
              {{option.label || option.value}}
            </label>
          </div>
        </li>
      </ul>


      <!-- <div [formGroup]="emailForm">
          <div class="form-floating mb-7 w-100 mt-10" *ngIf="messageAcceptOptions === 'Email'">
            <input type="email" class="form-control form-control-lg form-control-solid" id="email"
              placeholder="Email address" formControlName="email" />
            <label for="floatingInput">Email address</label>
            <span class="invalid-feedback d-block ms-1"
              *ngIf="emailForm.get('email')?.hasError('required') && (emailForm.get('email')?.touched || emailForm.get('email')?.dirty)">
              Email is required
            </span>
            <span class="invalid-feedback d-block ms-1"
              *ngIf="emailForm.get('email')?.hasError('email') && (emailForm.get('email')?.touched || emailForm.get('email')?.dirty)">
              Please enter a valid email
            </span>
          </div>
        </div> -->

      <!-- *ngIf="messageAcceptOptions === 'Other'" -->
      <div class="form-floating mb-7 w-100"
        [class.mt-5]="
        messageAcceptOptions === 'Other'
        "
        *ngIf="
        messageAcceptOptions === 'Other'
      ">
        <textarea class="form-control" [(ngModel)]="message" placeholder="Reason" id="" rows="5"></textarea>
        <label for="floatingTextarea ">Message</label>
      </div>


      <div class="form-floating mb-7 w-100"
        [class.mt-5]="
        messageAcceptOptions === 'Connect offline'
        "
        *ngIf="
        messageAcceptOptions === 'Connect offline'
      ">
        <textarea class="form-control" [(ngModel)]="message" placeholder="Enter text with relevant information to connect" id="" rows="5"></textarea>
        <label for="floatingTextarea ">Enter text with relevant information to connect</label>
      </div>


      <div class="form-floating mb-7 w-100 mt-5" *ngIf="
        messageAcceptOptions === 'Share meeting details'
      ">
        <textarea class="form-control" [(ngModel)]="message" placeholder="Enter meeting details" id=""
          rows="3"></textarea>
        <label for="floatingTextarea ">Enter meeting details</label>
      </div>


      <div class="form-floating mb-7 w-100 mt-5" *ngIf="
        messageAcceptOptions === 'Schedule call'
      ">


        <form *ngIf="meetingForm" [formGroup]="meetingForm" class="form fv-plugins-bootstrap5 fv-plugins-framework"
          id="kt_modal_add_event_form">

          <h4 for="floatingTextarea" class="mt-2 px-4">Schedule meeting</h4>


          <div class="row mb-8">
            <div class="col-12">
              <label class="fs-6 fw-bold required mb-2 d-block">Duration (mins)</label>
              <div
                class="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  *ngFor="let item of durationOptions"
                  type="button"
                  class="btn"
                  [ngClass]="
                    meetingForm.value.duration === item
                      ? 'btn-dark text-white'
                      : 'btn-outline  btn-outline-default'
                  "
                  (click)="onSelectDuration(item)"
                >
                  {{item}}
                </button>
              </div>
            </div>
          </div>


          <div class="py-4 px-4">
            <div class="row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">

              <div class="col-6">
                <label class="fs-6 fw-bold mb-2 required">Date</label>

                <input class="form-control" formControlName="date"
                  class="form-control form-control-solid flatpickr-input" placeholder="yyyy-mm-dd" name="dp"
                  [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" [minDate]="minDate" [markDisabled]="isDisabled"/>

                <div class="fv-plugins-message-container invalid-feedback"></div>
              </div>


              <!-- <div class="col-6">

                <div class="fv-row mb-9">
                  <label class="fs-6 fw-bold mb-2 required">Start Time</label>
                  <input formControlName="timeFrom" class="form-control form-control-solid flatpickr-input"
                    name="calendar_event_start_time" placeholder="Pick a start time"
                    id="kt_calendar_datepicker_start_time" type="time" (ngModelChange)="handleDurationChange()">
                </div>
              </div> -->

              <div class="col-6">
                <div class="fv-row mb-9">
                  <label class="fs-6 fw-bold mb-2">Start Time</label>

                  <!-- <select formControlName="timeFrom" class="form-control form-control-solid"
                    (ngModelChange)="handleDurationChange()">
                    <option value="">Select time</option>
                    <option *ngFor="let t of timeSlots" value="{{t}}">{{t | twentyFourHoursToAmPm}}</option>
                  </select> -->

                  <ng-select [items]="timeSlots" formControlName="timeFrom" [multiple]="false" placeholder="Select Time"
                  (change)="handleDurationChange()" [bindLabel]="'label'" [bindValue]="'value'" class="meeting-time-select"
                  appendTo="body">
                  <ng-template ng-label-tmp let-item="item">
                    <b>{{item.label}}</b>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <b>{{item.label}}</b>
                  </ng-template>
                </ng-select>

                </div>
              </div>

            </div>


            <!-- <div class="fv-row mb-5 fv-plugins-icon-container">
              <label class="fs-6 fw-bold required mb-2">Duration</label>
              <select formControlName="duration" class="form-control form-control-solid"
                (ngModelChange)="handleDurationChange()">
                <option value="">Select duration</option>
                <option value="30">30 minutes</option>
                <option value="45">45 minutes</option>
                <option value="60">60 minutes</option>
              </select>
              <div class="fv-plugins-message-container invalid-feedback"></div>
            </div> -->

          </div>
        </form>

      </div>





    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-lg btn-theme text-white ls-2 text-uppercase fw-boldest" [disabled]="isDisabledApply"
    (click)="onApplyBtnClick()">
    <!-- !messageAcceptOptions || (messageAcceptOptions === 'Email' && !emailForm.valid) || (messageAcceptOptions === 'Other' && !message)" -->
    {{ applyBtnText }}
  </button>
  <button class="btn btn-light ls-2 text-uppercase fw-boldest btn-active-light-primary me-2" (click)="closeModal()">
    {{ cancelBtnText }}
  </button>
</div>
