<div class="alert alert-success rounded fs-4 text-center py-5 flex flex-column mb-10"
  *ngIf="submitted && attendingType=='is_public'">
  <i class="bi bi-check-circle-fill text-green fw-boldest fs-3hx mb-5"></i>
  <p class="mt-3">
    Your details have been successfully submitted. An email acknowledgement will be sent to you shortly.
  </p>
</div>

<div *ngIf="!submitted">
  <div class="bg-white shadow-md rounded-md"
    *ngIf="(attendingType === 'is_public') ||(['not_one_to_one', 'one_to_one'].includes(attendingType) && eventQuestions.length)">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
      <p class="fw-bolder fs-4 mb-3 mb-md-0">Submit your information to attend</p>

      <div class="btn btn-icon btn-sm btn-color-gray-500 btn-active-icon-primary" (click)="close()"
        *ngIf="(['not_one_to_one', 'one_to_one'].includes(attendingType))" data-bs-toggle="tooltip" title=""
        data-bs-dismiss="kt_modal_view_event_edit" data-bs-original-title="Hide Event">

        <span class="svg-icon svg-icon-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
              fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor">
            </rect>
          </svg>
        </span>

      </div>

    </div>

    <form [formGroup]="QuestionForm" (ngSubmit)="attendEventFormSubmit()" class="space-y-4">
      <div class="agenda-container" *ngIf="attendingType=='one_to_one'">
        <label for="agenda" class="agenda-label">
          Agenda <span class="text-red">*</span>
        </label>
        <textarea id="agenda" formControlName="agenda" placeholder="Share the topic you would like to discuss"
          aria-label="Share the topic you would like to discuss" class="agenda-textarea">
      </textarea>
        <div *ngIf="QuestionForm.get('agenda')?.invalid && QuestionForm.get('agenda')?.touched" class="error-message">
          Agenda is required.
        </div>
      </div>

      <!-- Name -->
      <div class="col-12 col-md-12" *ngIf="attendingType=='is_public'">
        <label for="name" class="mb-2 fw-bold">Name<span class="text-danger">*</span></label>
        <input type="text" class="form-control" id="name" placeholder="Name" formControlName="name" [ngClass]="{
        'is-invalid': QuestionForm.get('name')?.invalid && (QuestionForm.get('name')?.touched || QuestionForm.get('name')?.dirty),
        'is-valid': QuestionForm.get('name')?.valid && (QuestionForm.get('name')?.touched || QuestionForm.get('name')?.dirty)
      }" />
        <span class="invalid-feedback d-block ms-1"
          *ngIf="QuestionForm.get('name')?.hasError('required') && QuestionForm.get('name')?.touched">
          Name is required
        </span>
      </div>

      <!-- Email -->
      <div class="col-12 col-md-12 mt-4" *ngIf="attendingType=='is_public'">
        <label for="email" class="mb-2 fw-bold">Email<span class="text-danger">*</span></label>
        <input type="text" class="form-control" id="email" placeholder="john.doe@gmail.com" formControlName="email"
          [ngClass]="{
        'is-invalid': QuestionForm.get('email')?.invalid && (QuestionForm.get('email')?.touched || QuestionForm.get('email')?.dirty),
        'is-valid': QuestionForm.get('email')?.valid && (QuestionForm.get('email')?.touched || QuestionForm.get('email')?.dirty)
      }" />
        <span class="invalid-feedback d-block ms-1"
          *ngIf="QuestionForm.get('email')?.hasError('required') && QuestionForm.get('email')?.touched">
          Email is required
        </span>
        <span class="invalid-feedback d-block ms-1" *ngIf="QuestionForm.get('email')?.hasError('email')">
          Enter a valid email
        </span>
      </div>

      <!-- Mobile Number -->
      <div class="col-12 col-md-12 mt-4" *ngIf="attendingType=='is_public'">
        <label for="mobileNumber" class="mb-2 fw-bold">Mobile Number</label>
        <div class="row g-0">
          <div class="col-3 pe-0">
            <select formControlName="countryCode" class="form-select">
              <option *ngFor="let country of COUNTRY_CODES_ARR" [value]="country.value">+{{ country.value }}</option>
            </select>
          </div>
          <div class="col-9 ps-3">
            <input type="tel" class="form-control" id="mobile-number" placeholder="Mobile number"
              formControlName="mobileNumber"
              [ngClass]="{ 'is-invalid': QuestionForm.get('mobileNumber')?.hasError('pattern') }" />
          </div>
          <span class="invalid-feedback d-block ms-1" *ngIf="QuestionForm.get('mobileNumber')?.hasError('pattern')">
            Enter a valid mobile number
          </span>
        </div>
      </div>

      <!-- Organization/ Designation -->
      <div class="col-12 col-md-12 mt-4" *ngIf="attendingType=='is_public'">
        <div class="row g-0">
          <div class="col-6 pe-0">
            <label for="organization" class="mb-2 fw-bold">Organization</label>
            <input type="text" class="form-control" id="organization" placeholder="Organization"
              formControlName="organization" />
          </div>
          <div class="col-6  ps-3">
            <label for="designation" class="mb-2 fw-bold">Designation</label>
            <input type="text" class="form-control" id="designation" placeholder="Designation"
              formControlName="designation" />
          </div>
        </div>
      </div>

      <!-- Dynamic Questions from API -->
      <ng-container *ngFor="let question of eventQuestions">
        <div class="col-12 mt-4">
          <label class="mb-2 fw-bold">
            {{ question.question }} <span *ngIf="question.mandatory" class="text-danger">*</span> <span class="bi bi-exclamation-circle fs-3 ms-2 text-primary d-inline-block cursor-pointer" *ngIf="question.description.length"
            ngbTooltip="{{question.description}}" placement="bottom"></span>
          </label>

          <!-- Text Input -->
          <input *ngIf="question.answerFieldType === 'text'" type="text" class="form-control"
            [formControlName]="'question_' + question.id" placeholder="Enter your answer">

            <!-- Company Description -->
            <textarea id="companyDescription" *ngIf="question.answerFieldType === 'textarea'" class="form-control"
              [formControlName]="'question_' + question.id" placeholder="Enter your answer"
              aria-label="Enter your answer">
                </textarea>

          <!-- Yes/No/Not Sure radio -->
          <div *ngIf="question.answerFieldType === 'boolean_only' || question.answerFieldType === 'boolean'"
            class="d-flex gap-3">
            <!-- Yes radio -->
            <div class="form-check">
              <input type="radio" class="form-check-input" id="yes-{{question.id}}"
                [checked]="QuestionForm.get('question_' + question.id)?.value === (question.answerFieldType === 'boolean' ? true : 'yes')"
                (change)="toggleCheckbox(question.id, question.answerFieldType, 'yes')">
              <label class="mb-2 fw-bold" for="yes-{{question.id}}">Yes</label>
            </div>

            <!-- No radio -->
            <div class="form-check check_space">
              <input type="radio" class="form-check-input" id="no-{{question.id}}"
                [checked]="QuestionForm.get('question_' + question.id)?.value === (question.answerFieldType === 'boolean' ? false : 'no')"
                (change)="toggleCheckbox(question.id, question.answerFieldType, 'no')">
              <label class="mb-2 fw-bold" for="no-{{question.id}}">No</label>
            </div>

            <!-- Not Sure radio (Only for `boolean_only`) -->
            <div *ngIf="question.answerFieldType === 'boolean_only'" class="form-check check_space">
              <input type="radio" class="form-check-input" id="dont-know-{{question.id}}"
                [checked]="QuestionForm.get('question_' + question.id)?.value === 'not sure'"
                (change)="toggleCheckbox(question.id, question.answerFieldType, 'not sure')">
              <label class="mb-2 fw-bold" for="dont-know-{{question.id}}">Not sure</label>
            </div>
          </div>
          <!-- Radio Button Type -->
          <div class="d-flex flex-column gap-2" *ngIf="question.answerFieldType === 'radio'">
            <div class="form-check" *ngFor="let option of question.options">
              <input type="radio" class="form-check-input" id="{{question.id}}-{{option}}" name="radio-{{question.id}}"
                [value]="option" [checked]="QuestionForm.get('question_' + question.id)?.value === option"
                (change)="onOptionSelect(question.id, option, 'radio')">
              <label class="mb-2" for="{{question.id}}-{{option}}">{{ option }}</label>
            </div>
          </div>

          <!-- Checkbox Type -->
          <div class="d-flex flex-column gap-2" *ngIf="question.answerFieldType === 'checkbox'">
            <div class="form-check" *ngFor="let option of question.options">
              <input type="checkbox" class="form-check-input" id="{{question.id}}-{{option}}"
                [checked]="QuestionForm.get('question_' + question.id)?.value?.includes(option)"
                (change)="onOptionSelect(question.id, option, 'checkbox', $event)">
              <label class="mb-2" for="{{question.id}}-{{option}}">{{ option }}</label>
            </div>
          </div>

          <!-- Numeric Input Field -->
          <div *ngIf="question.answerFieldType === 'numeric'">
            <input type="number" class="form-control" [formControlName]="'question_' + question.id"
              [attr.min]="question.minValue" [attr.max]="question.maxValue" placeholder="Enter a number"
              (input)="convertToPositive(question.id)">

            <span class="invalid-feedback d-block ms-1"
              *ngIf="QuestionForm.get('question_' + question.id)?.hasError('min')">
              Value should be greater than or equal to {{ question.minValue }}
            </span>
            <span class="invalid-feedback d-block ms-1"
              *ngIf="QuestionForm.get('question_' + question.id)?.hasError('max')">
              Value should be less than or equal to {{ question.maxValue }}
            </span>
          </div>


          <div *ngIf="question.answerFieldType === 'upload'">
            <div (mousedown)="activateDropzone(question.id)">
              <app-file-selector [allowdedMimeTypes]="allowdedMimeTypes" [accept]="accept" [acceptLabel]="acceptLabel"
                (fileSelect)="fileSubmit($event)" [isEventQuetion]="isEventQuetion">
              </app-file-selector>
            </div>
            <!-- Show uploaded files for this specific question ID -->
            <ng-container *ngIf="selectedImage[question.id] && selectedImage[question.id].length">
              <div class="position-relative w-fit me-3" *ngFor="let item of selectedImage[question.id]; let i = index">
                <div
                  class="btn-red p-3 cursor-pointer text-white d-flex justify-content-center align-items-center position-absolute"
                  style="height: 10px; width: 10px; border-radius: 50%; top: -3px; right: -11px;"
                  (click)="clearFileSelection(i, question.id)">
                  x
                </div>

                <div class="my-4">
                  <div class="d-flex align-items-center">
                    <div class="fi fi-{{fileExtension(item) ? fileExtension(item) : 'any'}} m-0">
                      <div class="fi-content">{{fileExtension(item) ? fileExtension(item) : 'any'}}</div>
                    </div>
                    <span class="ms-4">{{item.data[0].name}}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

        </div>

      </ng-container>

      <!-- Submit Button -->
      <div style="display: flex !important; justify-content: space-between;">
        <button type="submit" class="btn btn-theme text-white mb-3 mt-4 text-uppercase fw-boldest"
          [disabled]="loading || QuestionForm.invalid " *ngIf="!submitted">
          SUBMIT
          <span class="indicator-progress" [class.d-inline-block]="loading">
            <span class="spinner-border spinner-border-sm align-middle ms-2">
            </span>
          </span>
        </button>

        <button *ngIf="(['not_one_to_one', 'one_to_one'].includes(attendingType))"
          class="btn btn-light text-uppercase fw-boldest btn-active-light-primary mb-3 mt-4 " (click)="close()">
          CANCEL
        </button>
      </div>
    </form>
  </div>
</div>