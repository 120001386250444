import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PowerPitchService } from 'src/app/core/service/power-pitch.service';
import { GlobalService } from "src/app/core/service/global.service";

@Component({
  selector: 'app-connect-power-pitch-modal',
  templateUrl: './connect-power-pitch-modal.component.html',
  styleUrls: ['./connect-power-pitch-modal.component.scss']
})
export class ConnectPowerPitchModalComponent implements OnInit {

  isConnecting = false;
  modalInst: NgbModalRef;
  type: 'pitch' | 'call-for-application' = 'pitch'
  metaApiParams = {}
  constructor(
    private modal: NgbModal,
    private powerPitchService: PowerPitchService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {

  }

  closeModal(): void {
    this.modal.dismissAll();
  }

  handleConnect() {

    let connect ;
    if(this.type === 'pitch') {
      connect = this.powerPitchService.connectPowerPitch(this.metaApiParams)
    }
    if(this.type === 'call-for-application') {
      connect = this.powerPitchService.connectPowerPitchCallForApplication(this.metaApiParams)
    }



    this.isConnecting = true;
    connect.subscribe((r) => {

      let get_video ;
      if(this.type === 'pitch') {
        get_video = this.powerPitchService.getPowerPitchVideo(this.metaApiParams)
      }
      if(this.type === 'call-for-application') {
        get_video = this.powerPitchService.getPowerPitchVideoCallForApplication(this.metaApiParams)
      }


      this.isConnecting = false;
      get_video.subscribe((video) => {
        if (video) {
          console.log('getPowerPitchVideo ==> ', video)
          if (this.modalInst) {
            this.modalInst.close({ hasVideo: true })
          }
        }
      }, error => {
        if (error.status === 404) {
          let create_video ;
          if(this.type === 'pitch') {
            create_video = this.powerPitchService.createPowerPitchVideo(this.metaApiParams)
          }
          if(this.type === 'call-for-application') {
            create_video = this.powerPitchService.createPowerPitchVideoCallForApplication(this.metaApiParams)
          }
        // if (this.globalService.getErrorMessage(error) === 'Video not found') {
          create_video.then(pro => {
            pro.subscribe(res => {
              this.modalInst.close({ hasVideo: true, created: true })
            })
          })
        }
      })
    }, err => {
      this.isConnecting = false;
    })
  }

}
