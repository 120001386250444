import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import {
  Observable,
  throwError
} from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  map,
  tap
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import {
  MentorInformationModel, ServiceProviderProfileCompleteness
} from "../domain";
import { ApiEndpointService } from "./api-endpoint.service";
import * as ProfileActions from "src/app/core/state/profile/profile.action";
import * as ServiceProviderDashboardAction from 'src/app/core/state/service-provider-dashboard/service-provider.dashboard.action';
import { getProfileData } from "../state/profile";
import { IProfileDetails } from "../domain/profile.model";
import { GlobalService } from "./global.service";
import { IPowerPitch } from "../domain/power-pitch.model";
import { PublicApiService } from "./public-api.service";

@Injectable({
  providedIn: "root"
})
export class PowerPitchService {
  profileData: IProfileDetails;
  /**
   * Constructor.
   */
  constructor(private http: HttpClient, private toastService: ToastAlertService, private store: Store, private globalService: GlobalService
    , public publicApiService: PublicApiService) {
    // this.store.pipe(select(getProfileData))
    //   .pipe(distinctUntilChanged()).subscribe((res) => {
    //     if (res) {
    //       this.profileData = res;
    //     }
    //   })
  }


  public connectPowerPitch(params): Observable<IPowerPitch> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.POWER_PITCH) + 'connect';
    return this.http.post(url, {}).pipe(
      tap((res: any) => {
        console.log('token -->', res)
        const data: IPowerPitch = res.data
        if (data.powerPitchAccessToken) {
          localStorage.setItem('powerPitchAccessToken', data.powerPitchAccessToken)
        }

        this.toastService.showToast('Connected with powerpitch successfully!');

        // this.createPowerPitchVideo().then((res)=>{
        //   res.subscribe()
        // });
        // this.getPowerPitchVideo().subscribe()
      }),
      map((res: any) => {
        return res.data as IPowerPitch
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while connecting to power pitch!'), 'error');
        return throwError(() => fault);
      })
    );
  }


  public async createPowerPitchVideo(params): Promise<Observable<IPowerPitch>> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.POWER_PITCH) + 'video';


    const topics = await this.publicApiService.fetchPitchVideoTopics().toPromise()
    console.log('topics', topics)
    const payload = {
      videoTitle: 'Fundraising Video',
      videoType: 'fundraising',
      videoTopics: topics['fundraising'].sort((a,b)=>a.id-b.id)
    }
    const powerPitchAccessToken = localStorage.getItem('powerPitchAccessToken');
    return this.http.post(url, payload, {
      headers: {
        'x-power-pitch-token': powerPitchAccessToken
      }
    }).pipe(
      map((res: any) => {
        return res.data as IPowerPitch
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while creating video!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public getPowerPitchVideo(params): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.POWER_PITCH) + 'video';
    const powerPitchAccessToken = localStorage.getItem('powerPitchAccessToken');
    return this.http.get(url, {
      headers: {
        'x-power-pitch-token': powerPitchAccessToken
      }
    }).pipe(
      map((res: any) => {
        return res.data as IPowerPitch
      }),
      catchError((fault: HttpErrorResponse) => {
        // this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while creating video!'), 'error');
        return throwError(() => fault);
      })
    );
  }








  public connectPowerPitchCallForApplication(params): Observable<IPowerPitch> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.POWER_PITCH) + 'connect/call-for-applications';
    return this.http.post(url, params).pipe(
      tap((res: any) => {
        console.log('token -->', res)
        const data: IPowerPitch = res.data
        if (data.powerPitchAccessToken) {
          localStorage.setItem(`powerPitchAccessToken`, data.powerPitchAccessToken)
        }

        this.toastService.showToast('Connected with powerpitch successfully!');
      }),
      map((res: any) => {
        return res.data as IPowerPitch
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while connecting to power pitch!'), 'error');
        return throwError(() => fault);
      })
    );
  }


  public async createPowerPitchVideoCallForApplication(params): Promise<Observable<IPowerPitch>> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.POWER_PITCH) + 'video/call-for-applications';

    let topics = await this.publicApiService.fetchCallForApplicationPitchVideoTopics(params.programUUID).toPromise()
    if(!params.roundId) {
      topics = topics.filter(e=>e.programRoundId === null)
    }else{
      topics = topics.filter(e=>e.programRoundId === params.roundId)
    }
    const payload = {
      ...params,
      videoTitle: params.programTitle,
      videoType: 'fundraising',
      videoTopics: topics.sort((a,b)=>a.id-b.id)
    }
    const powerPitchAccessToken = localStorage.getItem(`powerPitchAccessToken`);
    return this.http.post(url, payload, {
      headers: {
        'x-power-pitch-token': powerPitchAccessToken
      }
    }).pipe(
      map((res: any) => {
        return res.data as IPowerPitch
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while creating video!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public getPowerPitchVideoCallForApplication(params): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.POWER_PITCH) + 'video/get/call-for-applications';
    const powerPitchAccessToken = localStorage.getItem(`powerPitchAccessToken`);
    return this.http.post(url,params, {
      headers: {
        'x-power-pitch-token': powerPitchAccessToken
      },

    },).pipe(
      map((res: any) => {
        return res.data as IPowerPitch
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }


}
