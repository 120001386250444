import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap, finalize, Observable, distinctUntilChanged, takeUntil, Subject } from 'rxjs';
import { ConnectionsService } from 'src/app/core/service/connections.service';
import { GetAllConnectionsReqPayload } from 'src/app/modules/connections/connections.model';
import { AddMeetingModalComponent } from '../add-meeting-button/add-meeting-modal/add-meeting-modal.component';
import { ConnectionUserInfoModalComponent } from 'src/app/modules/connections-v3/components/connection-user-info-modal/connection-user-info-modal.component';
import { IMeetingPatchModel, MeetingTime } from 'src/app/core/domain/meetings.model';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MeetingService } from 'src/app/core/service/meeting.service';
import { ToastAlertService } from '../../utils/toast.service';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getProfileData } from 'src/app/core/state/profile';
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import Swal from 'sweetalert2';
import { getBrandDetails } from 'src/app/core/state/global';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { MeetingLocationTypes, MeetingToolTypes } from '../../constants/enum';

@Component({
  selector: 'app-active-connection-actions',
  templateUrl: './active-connection-actions.component.html',
  styleUrls: ['./active-connection-actions.component.scss']
})
export class ActiveConnectionActionsComponent implements OnInit, OnDestroy {
  connectionItems: any[] = [];
  @Input() publicProfileUUID: string;
  foundConnection;
  private destroyed$: Subject<void> = new Subject();
  brandDetails: Partial<IBrandDetails>;

  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  ACCOUNT_TYPE = ACCOUNT_TYPE
  @Output() removeConnection = new EventEmitter();

  constructor(
    private connectionsService: ConnectionsService,
    private modalService: NgbModal,
    private uiLoader: NgxUiLoaderService,
    private meetingService: MeetingService,
    private toastAlertService: ToastAlertService,
    private router: Router,
    private readonly store: Store,
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res
      }
    })

    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      console.log({ res })
      if (res) {
        this.profileData = res;
        this.setAllConnectionsItems()
      }
    })

  }

  setAllConnectionsItems(): void {
    const payload: any = {
      sortBy: 'connectedAt',
      orderBy: 'DESC',
      pageNumber: 1,
      limit: 500
    }

    if (!this.brandDetails.features.connections) {
      return
    }
    this.connectionsService.getAllConnections(payload).pipe(tap((res: any) => {
      if (res.data) {
        this.connectionItems = res?.data?.items || [];
        console.log('connectionItems', this.connectionItems);
        this.foundConnection = this.connectionItems.find(e => e.companyUUID === this.publicProfileUUID)
        console.log('this.foundConnection', this.foundConnection)
      }
    }, (err) => {

    })).subscribe();
  }

  instantMeetingLoader = false
  async handleStartInstantMeeting() {

    this.instantMeetingLoader = true
    const { value: text, isDenied, isDismissed, isConfirmed } = await Swal.fire({
      title: 'Before proceeding to schedule an instant meeting, please confirm that the other party is prepared and available for it. Once confirmed, an email notification will be sent to them to inform them of the meeting details.',
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonColor: 'var(--color-theme)',
      cancelButtonColor: '#7D8185',
    })

    if (!isConfirmed) {
      this.instantMeetingLoader = false
      return
    }

    const otherUser = this.foundConnection
    let currentDate = new Date()
    currentDate.setMinutes(currentDate.getMinutes() + 2)
    const formatedDate = currentDate.toISOString().split('T')[0]

    const startTime = currentDate.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);

    currentDate.setHours(currentDate.getHours() + 2)
    const endTime = currentDate.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);


    const payload: IMeetingPatchModel = {
      otherUserUUID: otherUser.uid || otherUser.userUUID,
      date: formatedDate,
      timeFrom: startTime,
      timeTo: endTime,
      meetingTitle: `Meeting with ` + otherUser.name,
      meetingTimeType: MeetingTime.instant,
      meetingDescription: `Meeting with ` + otherUser.name,

      meetingLocationType: MeetingLocationTypes.VIRTUAL,
      meetingToolType: MeetingToolTypes.INBUILT,

      timeZone: moment.tz.guess(),
      offset: '' + new Date().getTimezoneOffset(),
    }

    this.uiLoader.startLoader('inst-meeting-loader');

    this.meetingService.createMeeting(payload).subscribe((response) => {
      if (response) {
        this.toastAlertService.showToast(response.message);
        const url = this.router.serializeUrl(this.router.createUrlTree(['/meetings/meeting/', response.data.uuid]));
        window.open(url, '_blank');
      }
      this.instantMeetingLoader = false
      this.uiLoader.stopLoader('inst-meeting-loader');
    }, (err) => {
      this.toastAlertService.showToast('Something went wrong while creating meeting', 'error')
      this.uiLoader.stopLoader('inst-meeting-loader');
      this.instantMeetingLoader = false
    })
  }



  handleCreateClick() {
    const otherUser = this.foundConnection
    const modal = this.modalService.open(AddMeetingModalComponent, {
      size: 'lg'
    })
    modal.componentInstance.modalData = {
      otherUser: otherUser
    }
  }


  handleViewContactDetails() {
    const otherUser = this.foundConnection
    console.log('otherUser', otherUser);
    const modal = this.modalService.open(ConnectionUserInfoModalComponent, {
      centered: true,
      size: 'lg'
    })
    modal.componentInstance.personalDetails = otherUser?.personalDetails
  }

  handleRemoveCollection() {
    if (this.foundConnection) {
      const { connectionUUID } = this.foundConnection
      console.log('otherUser', connectionUUID);
      // this.removeConnection.emit(otherUser.connectionUUID)
      // const modal = this.modalService.open(ConnectionUserInfoModalComponent, {
      //   centered: true,
      //   size: 'lg'
      // })
      // modal.componentInstance.personalDetails = otherUser?.personalDetails


      // removeConnection(event): void {
      const callRemoveAPI = () => {
        // this.ngxLoaderService.start();
        this.connectionsService.removeConnection(connectionUUID).pipe(tap((res: any) => {
          if (res) {
            // this.setConnectionsTypesCount();
            this.setAllConnectionsItems();
          }
        },
          // (err) => {
          //   this.ngxLoaderService.stop();
          // }), finalize(() => this.ngxLoaderService.stop()
        ))
          .subscribe();
      }

      Swal.fire({
        title: 'Remove connection',
        text: 'Are you sure you want to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'var(--color-theme)',
        cancelButtonColor: '#7D8185',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          callRemoveAPI();
        }
      });
      // }
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
