import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard";
import { getStartupProfileCompleteness, rasingFundRequestFault, rasingFundRequestSuccess } from 'src/app/core/state/startup-dashboard';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { ToastAlertService } from '../../utils/toast.service';
import Swal from 'sweetalert2';
import { Approval } from 'src/app/core/domain';
import { Router } from '@angular/router';
import { getStartUpInfo } from 'src/app/core/state/startup';
import { IStartUpInfoFetch } from 'src/app/core/domain/startup.model';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getProfileData } from 'src/app/core/state/profile';
import { createSlug, getMissingFieldsMap } from 'src/app/shared/utils/common-methods';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { getBrandDetails } from 'src/app/core/state/global';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareProfileModalComponent } from '../share-profile-modal/share-profile-modal.component';
import { getTitleFromNavLink, MissingFormKeyToUrlMapping } from '../../constants/constants';

@Component({
  selector: 'app-startup-raise-fund-switch',
  templateUrl: './startup-raise-fund-switch.component.html',
  styleUrls: ['./startup-raise-fund-switch.component.scss']
})
export class StartupRaiseFundSwitchComponent implements OnInit, OnDestroy {
  @Input() isUnderApproval = false;
  private destroyed$: Subject<void> = new Subject();
  missingFields: Record<string, Array<any>> = {};
  profileCompleteness;

  profileCompletenessPercentage = 0;
  canRequestApproval = false;
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;

  @Input() hideMissingFields = false;
  startupInfo: IStartUpInfoFetch;
  public startupInfo$: Observable<IStartUpInfoFetch>;
  public startupProfilePageUrl: string = "";
  brandDetails: Partial<IBrandDetails>;
  @Input() showShareFeedback = true;
  @Input() showEditProfileBtn = true;


  constructor(
    private readonly store: Store,
    private toastAlertService: ToastAlertService,
    private router: Router,
    public modal: NgbModal,
  ) {
    this.startupInfo$ = this.store.pipe(select(getStartUpInfo));
    this.getProfileData$ = this.store.pipe(select(getProfileData));
  }

  ngOnInit(): void {

    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res
      }
    })

    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;
      }
    })

    this.startupInfo$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res: any) => {
      if (res) {
        this.startupInfo = res;
        this.startupProfilePageUrl = `/startups/profile/${this.startupInfo.uuid}/${createSlug(this.startupInfo.companyName)}`;
        console.log(this.startupProfilePageUrl);
      }
    });

    this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
    this.store.pipe(select(getStartupProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
      if (res) {
        this.profileCompleteness = res;
        this.profileCompletenessPercentage = res?.percentage || 0;

        this.canRequestApproval = res?.canRequestApproval || false;
        this.profileCompleteness = res;
        this.isUnderApproval = this.profileCompleteness && this.profileCompleteness?.isApprovalRequested && !this.profileCompleteness?.canToggleStatus && !this.profileCompleteness?.isApproved;

        this.getMissingFields();
        if (localStorage.getItem('approval-modal') !== 'hide') {
          this.checkAndShowModalForSubmitProfile();
        }
      }
    });

    this.store.pipe(select(rasingFundRequestSuccess)).pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (res) {
        this.toastAlertService.showToast(res);
      }
    })

    this.store.pipe(select(rasingFundRequestFault)).pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (res) {
        this.toastAlertService.showToast(res, 'error');
      }
    })
  }


  getMissingFields = () => {
    this.missingFields = getMissingFieldsMap(this.profileCompleteness, 'startups')
  }

  onToggleChange($event) {
    this.store.dispatch(new StartupDashBoardActions.RasingFundRequest);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  checkAndShowModalForSubmitProfile() {
    // NOTE: temp closed for programs flow
    return;
    const isProfileModalOpen = localStorage.getItem('profile-modal-open') === 'true'

    if (this.brandDetails?.features?.video_pitch_mandatory && this.profileCompletenessPercentage >= 95 && !localStorage.getItem('first-login') && !this.profileCompleteness.isApprovalRequested && !this.profileCompleteness.canToggleStatus
      && !this.profileCompleteness.isRejected && !isProfileModalOpen) {
      Swal.fire({
        title: 'Awesome! Your profile is saved',
        text: 'You can also create/add your video pitch here.',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: 'var(--color-theme)',
        cancelButtonColor: '#7D8185',
        cancelButtonText: 'Skip',
        confirmButtonText: 'Get Set Go',
        // showDenyButton: true,
        // denyButtonText: 'Preview',
        denyButtonColor: '#000',
        allowOutsideClick: false,
        allowEscapeKey: false,
        preDeny: () => {
          const slug = this.startupInfo.companyName.split(' ').join('-').toLocaleLowerCase();

          const url = this.router.serializeUrl(this.router.createUrlTree(['/startups/profile/' + this.startupInfo.uuid, slug]));
          window.open(url, '_blank');
          return false;
        }
      }).then((result) => {
        if (result.isConfirmed) {
          // this.requestApproval();
          localStorage.setItem('approval-modal', 'hide');
          this.router.navigate(['/startups/edit/pitch-deck/fundraising-pitch'], {
            queryParams: {
              record: true
            }
          })
        } else {
          if (result.isDenied) {
            return false;
          } else {
            localStorage.setItem('approval-modal', 'hide');
          }
        }
      });
    }
  }


  onRequestApproval() {
    if (this.profileCompletenessPercentage >= 90 && !localStorage.getItem('first-login') && !this.profileCompleteness.isApprovalRequested && !this.profileCompleteness.canToggleStatus
      && !this.profileCompleteness.isRejected && !this.profileCompleteness?.isApproved) {
      Swal.fire({
        title: 'Awesome! your profile is now complete.',
        text: 'Please submit your profile/application.',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: 'var(--color-theme)',
        cancelButtonColor: '#7D8185',
        confirmButtonText: 'Submit',
        showDenyButton: true,
        denyButtonText: 'Preview',
        denyButtonColor: '#000',
        allowOutsideClick: false,
        allowEscapeKey: false,
        preDeny: () => {
          const slug = this.startupInfo.companyName.split(' ').join('-').toLocaleLowerCase();

          const url = this.router.serializeUrl(this.router.createUrlTree(['/startups/profile/' + this.startupInfo.uuid, slug]));
          window.open(url, '_blank');
          return false;
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.requestApproval();
        } else {
          if (result.isDenied) {
            return false;
          } else {
            localStorage.setItem('approval-modal', 'hide');
          }
        }
      });
    }
  }

  requestApproval() {
    const payload: Approval = {
      servicesLookingFor: []
    }
    this.store.dispatch(new StartupDashBoardActions.SendRequestApproval(payload));

    setTimeout(() => {
      //  && !this.startupInfo.programs?.length
      if (this.brandDetails.features.programs_public_view) {

        Swal.fire({
          text: 'Would you like to apply for the active programs?',
          title: 'Active Programs',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: 'var(--color-theme)',
          cancelButtonColor: '#7D8185',
          confirmButtonText: 'View programs',
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/programs'])
          }
        });

      }
      else {
        this.router.navigate(['/startups/dashboard']);
      }
    }, 1000)
  }

  handleShareButton() {
    this.modal.open(
      ShareProfileModalComponent, { centered: true, size: 'lg' }
    )
  }

  get isProfileCompleted() {
    return this.profileCompleteness?.percentage >= 100 && this.profileCompleteness?.isApproved;
  }

  get isEditProfileDisabled() {
    const backdoorLogin = localStorage.getItem('logged-in-from-backdoor');
    return this.brandDetails?.features?.disable_startup_profile_update && backdoorLogin !== 'true' && this.profileCompleteness?.approvalStatus === 'submitted'
  }

  get isLimitedAccess() {
    return this.profileCompleteness?.isRejected && this.brandDetails?.features?.limited_access && this.profileCompleteness?.approvalStatus === 'limited_access'
  }

  get editProfileVisible() {
    return this.profileCompleteness?.percentage < 100 && !this.hideMissingFields && this.showEditProfileBtn && !this.isUnderApproval
  }


  get isSubmitDisabled() {
    return !this.profileCompleteness?.canRequestApproval && this.profileCompleteness?.percentage < 95
    // !this.profileCompleteness?.isApprovalRequested
      // &&this.profileCompleteness?.canRequestApproval
      // && this.profileCompleteness?.percentage < 100
  }

}
