<div class="delete-loader" *ngIf="instantMeetingLoader">
  <ngx-ui-loader [loaderId]="'inst-meeting-loader'"></ngx-ui-loader>
</div>

<div class="d-flex justify-content-end align-items-center" *ngIf="foundConnection">

  <a class="btn btn-theme text-white ls-2 text-uppercase fw-boldest btn-lg me-3"
    *ngIf="brandDetails?.features?.chat"
      [routerLink]="'/chat/conversations/' + (foundConnection.groupChatUUID || foundConnection.userUUID)">Message
  </a>

  <div class="connections-page"
      >
      <div ngbDropdown container="body" data-kt-user-table-toolbar="base"
          class="d-flex justify-content-end">
          <a ngbDropdownToggle data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              class="btn btn-light ls-2 text-uppercase fw-boldest btn-active-light-primary btn-lg">Schedule Call
              <span class="svg-icon svg-icon-5 m-0"><svg
                      xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewBox="0 0 24 24" fill="none">
                      <path
                          d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                          fill="currentColor"></path>
                  </svg></span></a>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
              class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-auto">

              <div class="menu-item px-3">
                  <a ngbDropdownItem class="menu-link px-3 ps-5"
                      (click)="handleStartInstantMeeting()">
                      <span class="bi bi-camera-video-fill fs-3 me-3"></span>
                      Start instant meeting
                  </a>
                  <a ngbDropdownItem class="menu-link px-3 ps-5"
                      (click)="handleCreateClick()">
                      <span class="bi bi-calendar-date fs-3 me-3"></span>
                      Schedule for later
                  </a>
              </div>

          </div>
      </div>
  </div>

  <!-- <button class="btn btn-dark text-white ls-2 text-uppercase fw-boldest btn-lg ms-3"
  *ngIf="profileData?.accountType === ACCOUNT_TYPE.INVESTOR"
  (click)="handleViewContactDetails()">
        Contact details
  </button> -->


  <!-- <button class="btn btn-red text-white ls-2 text-uppercase fw-boldest btn-lg ms-3"
  ngbTooltip="Remove Connection"
  (click)="handleRemoveCollection()">
    x
  </button> -->


</div>
