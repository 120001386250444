import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, Observable, distinctUntilChanged, takeUntil, retry } from 'rxjs';
import { ICalenderEventModal } from 'src/app/core/domain/meetings.model';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { MeetingService } from 'src/app/core/service/meeting.service';
import { getProfileData } from 'src/app/core/state/profile';
import { getGlobalSettings } from 'src/app/core/state/global';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { GlobalService } from 'src/app/core/service/global.service';
import 'add-to-calendar-button';
import { ProfileService } from 'src/app/core/service/profile.service';
import { formatEnumStringForUI } from '../../utils/common-methods';
import Swal from 'sweetalert2';
import { ToastAlertService } from '../../utils/toast.service';
import lgZoom from 'lightgallery/plugins/zoom';
import { LightGalleryAllSettings } from 'lightgallery/lg-settings';

@Component({
  selector: 'app-event-details-one-on-one-modal',
  templateUrl: './event-details-one-on-one-modal.component.html',
  styleUrls: ['./event-details-one-on-one-modal.component.scss']
})
export class EventDetailsOneOnOneModalComponent implements OnInit {
  globalSettings: IGlobalSettings;
  settings: Partial<LightGalleryAllSettings> = {
    counter: false,
    plugins: [lgZoom],
  };

  @Input() modalInst: NgbModalRef;

  @Input() modalData: {
    action: string;
    event: any;
  };

  @Input() isRescheduling = false;
  bookingLoading = false;
  bookingLoadingForSlotTimeFrom;
  private destroyed$: Subject<void> = new Subject();
  public profileDetails$: Observable<IProfileDetails>;
  profileDetails: IProfileDetails;
  selectedResponse;
  eventQuestionsList;
  eventUUID;
  bookingData: any = {};
  showChildComponent = false;
  attendingType="one_to_one";
  constructor(
    private route: ActivatedRoute,
    private modal: NgbModal,
    private meetingService: MeetingService,
    private profileService: ProfileService,
    private router: Router,
    private readonly store: Store,
    private uiLoader: NgxUiLoaderService,
    private globalService: GlobalService,
    private toastService: ToastAlertService
  ) { }

  ngOnInit(): void {
    this.eventUUID=this.modalData.event.uuid;
    if (this.modalData?.event?.checkIfResponseExists?.responseAction) {
      this.selectedResponse = { value: this.modalData.event?.checkIfResponseExists?.responseAction }
    }

    this.profileDetails$ = this.store.pipe(select(getProfileData));
    this.profileDetails$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) { this.profileDetails = res; }
    })
    this.store.pipe(select(getGlobalSettings)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res;
      }
    })

    if (this.modalData.event?.slots?.length) {
      this.modalData.event.slots = this.mapSlotsWithBreaks()

      this.selectSlotDate(this.modalData.event.slots?.[0])
    }

    this.globalService.getEventQuestions(this.modalData.event.uuid).subscribe(
      (response: any) => {
        this.eventQuestionsList = response;
      })
  }

  mapSlotsWithBreaks() {
    const newSlots = this.modalData.event.slots.map((e) => {
      const originalSlots = e.slots;
      const breakSlots = e.breakSlots.flatMap((breakSlot) =>
        breakSlot.slots.map((slot) => ({ ...slot, title: breakSlot.title }))
      );


      let updatedSlots = originalSlots.map((slot) => {
        const matchingBreak = breakSlots.find(
          (breakSlot) =>
            slot.timeFrom >= breakSlot.timeFrom && slot.timeTo <= breakSlot.timeTo
        );
        return {
          ...slot,
          break: matchingBreak ? { title: matchingBreak.title } : null,
        };
      });


      updatedSlots = this.mergeConsecutiveBreaks(updatedSlots)
      return {
        ...e,
        slots: updatedSlots,
      };
    });

    return newSlots;
  }
  mergeConsecutiveBreaks(slots) {
    const mergedSlots = [];
    let tempSlot = null;

    slots.forEach(slot => {
      if (slot.break) {

        if (tempSlot && tempSlot.break.title === slot.break.title) {
          tempSlot.timeTo = slot.timeTo; // Extend the timeTo of the break
        } else {

          if (tempSlot) {
            mergedSlots.push(tempSlot);
          }
          tempSlot = { ...slot };
        }
      } else {

        if (tempSlot) {
          mergedSlots.push(tempSlot);
          tempSlot = null;
        }
        mergedSlots.push(slot);
      }
    });

    if (tempSlot) {
      mergedSlots.push(tempSlot);
    }

    return mergedSlots;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }


  get meetingUrl() {
    const event: any = this.modalData.event
    const link = this.router.serializeUrl(this.router.createUrlTree(event.meetingType === 'job-interview' ? ['/job-interview/', event.uuid] : ['/meetings/meeting/' + event.uuid]));
    return window.location.origin + link
  }
  meetingDetail(event) {
    this.closeModal();
    // this.router.navigate(event.meetingType === 'job-interview' ? ['/job-interview/', event.uuid] : ['/meetings/meeting/' + event.uuid])
    const link = this.router.serializeUrl(this.router.createUrlTree(event.meetingType === 'job-interview' ? ['/job-interview/', event.uuid] : ['/meetings/meeting/' + event.uuid]));
    window.open(link, '_blank');
  }


  closeModal(): void {
    this.modalInst.close({});
  }

  get isInPast() {
    return moment(this.modalData?.event?.date).isBefore(moment(), 'date')
  }

  tConvert(time, date) {
    return time
  }

  get eventDate() {
    return new Date(this.modalData.event.date)
  }


  formatTo24Hour(timeAMPM) {
    const time24Hour = moment(timeAMPM, 'h:mm A').format('HH:mm');
    return time24Hour
  }

  // selectResponse(item) {
  //   const event: any = this.modalData.event

  //   this.selectedResponse = item;

  //   this.globalService.eventResponse(event.id, item.value).subscribe()
  // }

  attendeeDetailBookedRes;
  async handleBook(slot, date) {
    // console.log('this.modal', this.modalData)
    // return
if(this.eventQuestionsList.length){
  this.showChildComponent=true;
  this.bookingData = {
    event: this.modalData.event,
    date:date,
    SlotTime: slot.timeFrom,
    timeFrom: slot.timeFrom.split(":").slice(0, 2).join(':'),
    timeTo: slot.timeTo.split(":").slice(0, 2).join(':'),
    attending:'attending',
    message:"Slot was successfully booked."
  }
}
else{

    const { value: text, isConfirmed } = await Swal.fire({
      input: "textarea",
      title: "<p class='fs-2 mt-4'>Agenda <span class='text-red'>*</span></p>",
      inputPlaceholder: "Share the topic you would like to discuss",
      inputAttributes: {
        "aria-label": "Share the topic you would like to discuss"
      },
      showCancelButton: true,
      confirmButtonColor: 'var(--color-theme)',
      cancelButtonColor: '#7D8185',
      confirmButtonText: 'Submit'
    });
    if(isConfirmed) {
      if (text) {
        this.bookingLoading = true;
        const event: any = this.modalData.event
        console.log('event', event)
        this.bookingLoadingForSlotTimeFrom = slot.timeFrom;
        const timeFrom = slot.timeFrom.split(":").slice(0, 2).join(':')
        const timeTo = slot.timeTo.split(":").slice(0, 2).join(':')
        this.globalService.bookEventSlot(event.uuid, timeFrom, timeTo, 'attending', text, date, "Slot was successfully booked.").subscribe(res => {
          this.bookingLoading = false;
          this.attendeeDetailBookedRes = res
          // this.closeModal()
        });

      }else{
        this.toastService.showToast('Agenda is required to book a slot', 'error')
      }
    }
  }

  }

  get speakerProfileLink() {
    if (this.modalData?.event?.speakerCompanyDetails) {
      return this.profileService.getProfileLink(
        this.modalData?.event?.speakerCompanyDetails?.uuid,
        this.modalData?.event?.speakerCompanyDetails?.name,
        this.modalData?.event?.speakerDetails?.accountType
      )
    }
    return ''
  }

  selectedSlotObj = null
  selectSlotDate(slotObj) {
    this.selectedSlotObj = slotObj
  }

  get attendeeDetail() {
    const attend = this.modalData.event?.attendeeDetail || this.modalData.event?.checkIfResponseExists || this.attendeeDetailBookedRes
    if (attend?.approvalStatus === 'pending_moderation' || attend?.approvalStatus === 'approved') {
      return attend
    }
    return undefined
  }

  get slots() {
    return (this.modalData?.event?.slots || []).filter(e => !e.booked)
  }

  formatEnumStringForUI(input) {
    return formatEnumStringForUI(input)
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


  get mainSpeaker(){
    const speaker = this.modalData?.event?.speakerDetails;
    const speakerCompanyDetails = this.modalData.event?.speakerCompanyDetails;
    if (!speaker) {
      return {}
    }
    return {
        "bio": this.modalData?.event.speakerBio,
        "name": speaker?.name,
        "email": "",
        "mobile": null,
        "imageKey": null,
        "linkedin":this.modalData?.event.speakerLinkedin,
        "existingImage": speakerCompanyDetails?.logo || speaker.avatar,
        "speaker_image": speakerCompanyDetails?.logo || speaker.avatar,

    }
  }

  handleMainSpeakerClick() {
    const link = this.profileService.getProfileLink(this.modalData.event.speakerCompanyDetails.uuid, this.modalData.event.speakerCompanyDetails.name, this.modalData.event.speakerDetails.accountType)
    
    // this.router.navigateByUrl(link);
    const url = this.router.serializeUrl(
      this.router.createUrlTree([link])
    );
    window.open(url, '_blank');
    // this.closeModal();
  }
  closeChild() {
    this.selectedResponse = { value: this.modalData.event?.checkIfResponseExists?.responseAction};
    this.showChildComponent = false;
  }
  bookingResponseUpdate(eventData: any): void {
    this.attendeeDetailBookedRes=eventData;
    this.selectedResponse = { value: eventData.responseAction };
  }
}
