import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/core/service/global.service';
import { COUNTRY_CODES_ARR } from '../../constants/country-code';
import { lastValueFrom, take } from 'rxjs';
import { ProfileService } from 'src/app/core/service/profile.service';

@Component({
  selector: 'app-attend-information',
  templateUrl: './attend-information.component.html',
  styleUrls: ['./attend-information.component.scss']
})
export class AttendInformationComponent implements OnInit {
  loading = false;
  submitted = false;
  isEventQuetion: boolean = true;
  QuestionForm: FormGroup;
  eventQuestions: any[] = [];
  eventQuestion_FileId;
  selectedImage: { [key: number]: any[] } = {};

  @Input() eventId: string;
  @Input() existingAttendence: { existingAttendence: any; value: any, event: any, modalData: any };
  @Input() eventQuestionList:[];
  @Input() attendingType: string;
  @Input() bookingData: { event: any; date: any, SlotTime: any, timeFrom: any,timeTo:any,attending:any ,message:any};

  @Output() closeComponent = new EventEmitter<void>();
  @Output() modalDataChange = new EventEmitter<any>();
  @Output() bookingResponseData= new EventEmitter<any>();

  COUNTRY_CODES_ARR = COUNTRY_CODES_ARR;
  readonly allowdedMimeTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ]
  accept = '.pdf, .doc, .docx, .rtf, .ppt, .pptx, .xls, .xlsx';
  acceptLabel = 'ppt, pptx, doc, docx, pdf, xls, xlsx';
  activeDropzone: number | null = null;
  bookingResponse;

  constructor(private fb: FormBuilder, private globalService: GlobalService, private profileService: ProfileService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initializeForm();
    if (this.eventId) {
      this.getEventQuestions(this.eventId);
    }
  }

  close() {
    this.closeComponent.emit();
  }

  initializeForm() {
    this.QuestionForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      countryCode: ['91'],
      mobileNumber: ['', [Validators.pattern('^[0-9]{10}$')]],
      organization: [''],
      designation: [''],
      agenda: ['', Validators.required]
    });

    if (['not_one_to_one', 'one_to_one'].includes(this.attendingType)) {
      this.profileService.getProfileDetails()
        .pipe(take(1))
        .subscribe((profileData) => {
          if (profileData) {
            this.QuestionForm.patchValue({
              name: profileData.name || '',
              email: profileData.email || '',
              countryCode: profileData.countryCode ? String(profileData.countryCode) : '91',
              mobileNumber: profileData.mobileNumber || '',
              designation: profileData.designation || ''
            });
          }
        });
    }
    this.toggleAttendingState();
  }

  toggleAttendingState() {
    const fieldsToToggle = ['name', 'email', 'countryCode', 'mobileNumber', 'designation', 'organization'];
    const shouldDisable = this.attendingType === 'not_one_to_one' || this.attendingType === 'one_to_one';
  
    fieldsToToggle.forEach(field => 
      shouldDisable ? this.QuestionForm.get(field)?.disable() : this.QuestionForm.get(field)?.enable()
    );

    this.QuestionForm.get('agenda')?.setValidators(
      this.attendingType === 'one_to_one' ? Validators.required : null
    );
    this.QuestionForm.updateValueAndValidity();
  }
  
  getEventQuestions(eventId: string) {
    if(['not_one_to_one', 'one_to_one'].includes(this.attendingType)){
      this.eventQuestions=this.eventQuestionList
      this.addDynamicControls();

    }else{
    this.globalService.getEventQuestions(eventId).subscribe(
      (response: any) => {
        this.eventQuestions = response;
        this.addDynamicControls();

      },
      (error) => {
        this.eventQuestions = [];
      }
    );
  }
  }

  addDynamicControls() {
    const controls: any = {};
    this.eventQuestions.forEach((question) => {
      const validators = [];

      if (question.mandatory) {
        validators.push(Validators.required);
      }

      if (question.answerFieldType === 'numeric' && question.minValue !== null) {
        validators.push(Validators.min(question.minValue));
      }
      if (question.answerFieldType === 'numeric' && question.maxValue !== null) {
        validators.push(Validators.max(question.maxValue));
      }
  
      try {
        question.options = JSON.parse(question.options);
      } catch (error) {
        question.options = [];
      }

      if (question.answerFieldType === 'checkbox') {
        this.QuestionForm.addControl(`question_${question.id}`, this.fb.control([], validators)); 
      } else {
        this.QuestionForm.addControl(`question_${question.id}`, this.fb.control(null, validators));
       }

    });
  
    this.QuestionForm = this.fb.group({ ...this.QuestionForm.controls, ...controls });
  }

  activateDropzone(questionId: number) {
    this.activeDropzone = questionId;
  }

  async fileSubmit(files: File | File[]) {
    let questionId = this.activeDropzone;

    if (!files || this.activeDropzone !== questionId) return;
    const fileArray = Array.isArray(files) ? files : [files];

    if (!this.selectedImage) {
      this.selectedImage = {};
    }

    if (!this.selectedImage[questionId]) {
      this.selectedImage[questionId] = [];
    } else {
      this.selectedImage[questionId] = [...this.selectedImage[questionId]];
    }

    fileArray.forEach((file) => {
      const alreadyExists = this.selectedImage[questionId].some(existingFile => existingFile.name === file.name);
      if (!alreadyExists) {
        this.selectedImage[questionId].push(file);

      }
    });

    this.selectedImage = { ...this.selectedImage };
    this.onValidationCheck(questionId);
    await this.uploadAttachment(this.eventId, questionId);
  }

  clearFileSelection(index: number, questionId: number) {
    if (this.selectedImage[questionId]) {
      this.selectedImage[questionId].splice(index, 1);
      this.selectedImage = { ...this.selectedImage };
    }
    this.onValidationCheck(questionId);
  }

  onValidationCheck(questionId: number) {
    const control = this.QuestionForm.get('question_' + questionId);

    if (this.selectedImage[questionId] && this.selectedImage[questionId].length > 0) {
      control.clearValidators();
    } else {
      control.setValidators([Validators.required]);
    }
    control.updateValueAndValidity();
  }

  fileExtension(file) {
    if (!file || !file.data[0].name) {
      return '';
    }
    const splitted = file.data[0].name.split('.');
    return splitted[splitted.length - 1];
  }

  private async uploadAttachment(eventAttendeeId: string, questionId: number): Promise<void> {
    try {
      if (!this.selectedImage || Object.keys(this.selectedImage).length === 0) {
        return;
      }

      const files = this.selectedImage[questionId];
      if (files.length > 0) {
        for (const file of files) {
          const uploadRes = await lastValueFrom(this.globalService.uploadFile(file.data[0], eventAttendeeId));
          const key = `question_${questionId}`;
          if (!this.QuestionForm.controls[key]) {
            this.QuestionForm.addControl(key, new FormControl(''));
          }
          this.QuestionForm.patchValue({ [key]: uploadRes.data });
        }
      }

    } catch (error) {
      throw error;
    }
  }

  toggleCheckbox(questionId: number, answerType: string, selectedValue: string) {
    const control = this.QuestionForm.get('question_' + questionId);
    if (!control) return;

    let valueToSet: boolean | string | null = null;
    if (answerType === 'boolean' || answerType === 'boolean_only') {
      valueToSet = selectedValue;
    }
    control.setValue(valueToSet);
  }

  onOptionSelect(questionId: number, value: any, type: string, event?: any): void {
    if (type === 'radio') {
      this.QuestionForm.get('question_' + questionId)?.setValue(value);
    } else if (type === 'checkbox') {
      let selectedValues = this.QuestionForm.get('question_' + questionId)?.value || [];
      if (event.target.checked) {
        selectedValues.push(value);
      } else {
        selectedValues = selectedValues.filter((val: string) => val !== value);
      }
      this.QuestionForm.get('question_' + questionId)?.setValue(selectedValues);
    }
  }

  convertToPositive(questionId: number) {
    const control = this.QuestionForm.get('question_' + questionId);
    if (!control) return;

    let value = control.value;
    if (value === '-' || value === null || value === '') {
      control.setValue('', { emitEvent: false });
    }else if (value < 0) {
      control.setValue(Math.abs(value), { emitEvent: false });
    }

    control.updateValueAndValidity();
  }

  async attendEventFormSubmit() {
    this.loading = true;
    this.submitted = false;

    if (!this.QuestionForm.valid) {
      this.loading = false;
      return;
    }

    const formValues = this.QuestionForm.value;
    const payload = {
      name: formValues.name,
      email: formValues.email,
      countryCode: parseInt(formValues.countryCode, 10),
      mobileNumber: `${formValues.countryCode} ${formValues.mobileNumber}`,
      organization: formValues.organization,
      designation: formValues.designation
    };

    try {
      let eventResponse;

      if (['is_public', 'one_to_one'].includes(this.attendingType)) {
        eventResponse = await this.attendEvent(payload);
        this.bookingResponseData.emit(eventResponse);
      } else {
        eventResponse = await this.existingEvent( this.existingAttendence?.existingAttendence?.uuid);
      }

      this.bookingResponseData.emit(eventResponse);
      this.submitted = true;

      if (this.eventQuestions.length) {
        await this.submitEventQuestionsAnswers(this.eventId, formValues, eventResponse);
      }
    } catch (error) {
      console.error("Error in attending information submission:", error);
    } finally {
      this.loading = false;
    }
  }

  private async attendEvent(payload): Promise<any> {
    try {
      let eventURL =this.attendingType=='one_to_one'?  this.globalService.bookEventSlot(this.eventId, this.bookingData.timeFrom, this.bookingData.timeTo, this.bookingData.attending, this.QuestionForm.get('agenda')?.value, this.bookingData.date, this.bookingData.message):this.globalService.attendPlatformEventPublic_Second(this.eventId, payload)
      return await lastValueFrom(eventURL);
    } catch (error) {
      throw error;
    }
  }

  private async existingEvent(existingUuid) {
    let eventResponse;
    if (existingUuid) {
      eventResponse = this.existingAttendence?.existingAttendence;
      const res = await this.globalService.updateWebinarEventBooking(this.existingAttendence.event.uuid,existingUuid,this.existingAttendence.value).toPromise();
      eventResponse = res || eventResponse;
  
      if (this.existingAttendence.modalData?.event?.checkIfResponseExists) {
        this.existingAttendence.modalData.event.checkIfResponseExists.responseAction = this.existingAttendence.value;
        this.modalDataChange.emit(this.existingAttendence.modalData);
      } else {
        eventResponse.responseAction = "attending";
      }
    } else {
      eventResponse = await this.globalService
        .bookEventSlot(this.existingAttendence.event.uuid,null,null,this.existingAttendence.value,null,null,"You have successfully registered for the event").toPromise();
    }
    return eventResponse;
  }
  
  private async submitEventQuestionsAnswers(eventAttendeeId: string, formValues, eventResponse): Promise<void> {
    const eventQuestionAnswers = Object.keys(formValues)
      .filter(key => key.startsWith('question_'))
      .map(key => {
        let answer = formValues[key];
        if (Array.isArray(answer)) {
          answer = answer.join(', ');
        }
        return {
          questionId: parseInt(key.replace('question_', ''), 10),
          answer: answer
        };
      });
  
    const payloadQue = { eventAttendeeId: eventResponse.id, eventQuestionAnwers: eventQuestionAnswers };
    console.log("payloadQue==", payloadQue);
  
    return new Promise((resolve, reject) => {
      this.globalService.submitEventQuestionsAnswers(payloadQue, eventAttendeeId).subscribe(
        (response) => {
          resolve(response);
          this.loading = false;
          if (['not_one_to_one', 'one_to_one'].includes(this.attendingType)) {
            this.closeComponent.emit();
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  
}