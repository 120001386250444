import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { IMeetingPatchModel, MeetingTime } from 'src/app/core/domain/meetings.model';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { MeetingService } from 'src/app/core/service/meeting.service';
import { getGlobalSettings } from 'src/app/core/state/global';
import { ToastAlertService } from '../../utils/toast.service';
import { AddMeetingModalComponent } from './add-meeting-modal/add-meeting-modal.component';
import Swal from 'sweetalert2';
import { MeetingLocationTypes, MeetingToolTypes } from '../../constants/enum';

@Component({
  selector: 'app-add-meeting-button',
  templateUrl: './add-meeting-button.component.html',
  styleUrls: ['./add-meeting-button.component.scss']
})
export class AddMeetingButtonComponent implements OnInit, OnDestroy {

  @Input() otherUser;
  private destroyed$: Subject<void> = new Subject();
  public globalSettings$: Observable<IGlobalSettings>;
  globalSettings: IGlobalSettings;

  constructor(
    private modalService: NgbModal,
    private meetingService: MeetingService,
    private toastAlertService: ToastAlertService,
    private router: Router,
    private uiLoader: NgxUiLoaderService,
    private store: Store
  ) { }

  ngOnInit(): void {
    console.log('other user', this.otherUser);

    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));

    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res;
      }
    })
  }

  handleCreateClick() {
    const modal = this.modalService.open(AddMeetingModalComponent, {
      size: 'lg'
    })
    modal.componentInstance.modalData = {
      otherUser: this.otherUser
    }
  }

  async handleStartInstantMeeting() {


    const { value: text, isDenied, isDismissed, isConfirmed } = await Swal.fire({
      title: 'Before proceeding to schedule an instant meeting, please confirm that the other party is prepared and available for it. Once confirmed, an email notification will be sent to them to inform them of the meeting details.',
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonColor: 'var(--color-theme)',
      cancelButtonColor: '#7D8185',
    })

    if (isConfirmed) {

      let currentDate = new Date()
      const formatedDate = currentDate.toISOString().split('T')[0]

      const startTime = currentDate.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);

      currentDate.setHours(currentDate.getHours() + 2)
      const endTime = currentDate.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);


      const payload: IMeetingPatchModel = {
        otherUserUUID: this.otherUser.uid || this.otherUser.userUUID,
        date: formatedDate,
        timeFrom: startTime,
        timeTo: endTime,
        meetingTitle: `Meeting with ` + this.otherUser.name,
        meetingTimeType: MeetingTime.instant,
        meetingDescription: `Meeting with ` + this.otherUser.name,
        timeZone: moment.tz.guess(),
        offset: '' + new Date().getTimezoneOffset(),
        meetingLocationType: MeetingLocationTypes.VIRTUAL,
        meetingToolType: MeetingToolTypes.INBUILT,
      }

      this.uiLoader.start();

      this.meetingService.createMeeting(payload).subscribe((response) => {
        if (response) {
          this.toastAlertService.showToast(response.message);
          const url = this.router.serializeUrl(this.router.createUrlTree(['/meetings/meeting/', response.data.uuid]));
          window.open(url, '_blank');
        }
        this.uiLoader.stop();
      }, (err) => {
        this.toastAlertService.showToast('Something went wrong while creating meeting', 'error')
        this.uiLoader.stop();
      })

    }




  }


  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
