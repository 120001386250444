import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MeetingService } from 'src/app/core/service/meeting.service';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';
import { ICalenderEventModal, IMeetingAvailability, METTING_AVAILABILITY, MeetingTime } from 'src/app/core/domain/meetings.model';
import * as moment from 'moment';
import { NgbTimeStringAdapter } from 'src/app/shared/common-components/add-meeting-button/add-meeting-modal/add-meeting-modal.component';
import { add, differenceInMinutes } from 'date-fns';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { calculateSlots, convertToLocalTime, createTimeSlots, filterAvailableSlots, findNextAvailableDate, generateTimeSlots, generateTimeSlotsWithAMPM, isDisabledDateForMeeting } from 'src/app/shared/utils/common-methods';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { getBrandDetails } from 'src/app/core/state/global';


@Component({
  selector: 'app-edit-meeting-event',
  templateUrl: './edit-meeting-event.component.html',
  styleUrls: ['./edit-meeting-event.component.scss'],
  providers: [
    { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
    // { provide: NgbDateAdapter, useClass: CustomDateAdapter },
  ]
})
export class EditMeetingComponent implements OnInit, OnDestroy {
  model: NgbDateStruct;
  @Output() refreshData = new EventEmitter<any>();
  meetingForm: FormGroup | any;
  @Input() modalData: {
    action: string;
    event: ICalenderEventModal;
  };
  @Input() isRescheduling = false;
  @Input() acceptReschedule = false;
  calenderAvilablity: IMeetingAvailability;
  disabledDaysIndex = []
  timeSlots: Array<{
    label: string,
    value: string
  }> = []
  METTING_AVAILABILITY = METTING_AVAILABILITY;
  private destroyed$: Subject<void> = new Subject();
  brandDetails: Partial<IBrandDetails>;


  constructor(
    private meetingService: MeetingService,
    private toastAlertService: ToastAlertService,
    private modal: NgbModal,
    private uiLoader: NgxUiLoaderService,
    private calendar: NgbCalendar,
    private store: Store,
  ) { }

  isDisabled = (date: NgbDate, current: { month: number }) => {
    return isDisabledDateForMeeting(date,this.calenderAvilablity,this.calendar,this.disabledDaysIndex)
  };


  async ngOnInit() {


    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe(async (res) => {
      if (res) {
        this.brandDetails = res;


        console.log('this.modal', this.modalData.event)
        // console.log('isRescheduling', this.isRescheduling)
        console.log('isRescheduling', this.acceptReschedule)
        await this.getMeetingAvailability(this.modalData.event.receiver?.uuid);
        this.meetingForm = new FormGroup({
          date: new FormControl('', [Validators.required]),
          timeFrom: new FormControl(null, [Validators.required]),
          timeTo: new FormControl('', [Validators.required]),
          meetingTitle: new FormControl('', [Validators.required]),
          otherUserUUID: new FormControl(''),
          duration: new FormControl('30', [Validators.required]),
        });


        const recalculateSlots = (date, duration?) => {
          if (this.calenderAvilablity.availabilityHours === METTING_AVAILABILITY.specific_days || this.calenderAvilablity.availabilityHours === METTING_AVAILABILITY.anytime) {
            this.calculateSlots(date, duration)
          }
        }

        this.meetingForm.get('date').valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
        .subscribe((date) => {
          if(date) {
            recalculateSlots(date)
          }
        })
        this.meetingForm.get('duration').valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
        .subscribe((duration) => {
          recalculateSlots(this.meetingForm.value['date'], duration)
        })

        if (this.modalData && this.modalData.event) {
          let { date } = this.modalData.event

          let timeFromFormatted = moment(this.modalData.event.timeFrom, ["h:mm A"]).format("HH:mm");
          let timeToFormatted = moment(this.modalData.event.timeTo, ["h:mm A"]).format("HH:mm");

          let duration = '30';
          if (this.acceptReschedule) {
            date = this.modalData.event.proposedDate;
            timeFromFormatted = moment(this.modalData.event.proposedTimeFrom, ["h:mm A"]).format("HH:mm");
            timeToFormatted = moment(this.modalData.event.proposedTimeTo, ["h:mm A"]).format("HH:mm");

            const diffInMinutes = moment(this.modalData.event.proposedTimeFrom, ["h:mm A"]).diff(moment(this.modalData.event.proposedTimeTo, ["h:mm A"]), 'minutes')
            duration = '' + Math.abs(diffInMinutes);
          }

          const nextAvailableDate = findNextAvailableDate(
            this.calenderAvilablity,
            this.calendar,
            this.disabledDaysIndex
          )
          if(nextAvailableDate) {
            this.model = nextAvailableDate
            console.log('calenderAvilablity abcd', this.calenderAvilablity  , this.disabledDaysIndex)
          }
          this.meetingForm.patchValue({
            meetingTitle: this.modalData.event.meetingTitle,
            date: this.modalData.event.date,
            timeFrom: timeFromFormatted,
            timeTo: timeToFormatted,
            otherUserUUID: this.modalData.event.receiver?.uuid,
            duration
          });
          console.log('forromro', this.meetingForm.value)
          this.handleDurationChange()
        }
    }})


  }



  async calculateSlots(date, duration = this.meetingForm?.value?.['duration']) {
    if(!date?.day) {
      return
    }
    const otherUserId = this.modalData.event.receiver?.uuid;
    const month = ('' + date.month).length === 2 ? date.month : '0' + date.month
    const day = ('' + date.day).length === 2 ? date.day : '0' + date.day
    const selectedDateFormatted = `${date.year}-${month}-${day}`

    const dateAvailability = await this.meetingService.getUsersAvailabilityByDate(otherUserId, selectedDateFormatted).toPromise()

    this.timeSlots = await calculateSlots({
      date,
      brandDetails: this.brandDetails,
      calendar:this.calendar,
      calenderAvilablity: this.calenderAvilablity,
      dateAvailability,
      timeSlots:this.timeSlots
    })
    // NOTE: remove last slot, ex. 9-7 available (slot with time 7 will be remove)
    // this.timeSlots.pop()
    if(this.timeSlots.length){
      this.timeSlots = filterAvailableSlots(this.timeSlots,this.timeSlots[this.timeSlots.length-1].value, duration)
    }
  }

  async getMeetingAvailability(uid: string) {
    const res = await this.meetingService.getUsersAvailabilityById(uid).toPromise()
    this.calenderAvilablity = res.data || {};
    if (this.calenderAvilablity.availabilityHours === METTING_AVAILABILITY.specific_days) {
      this.disabledDaysIndex = (this.calenderAvilablity.days||[]).filter((e) => e.closed).map(e => e.dayIndex)
      console.log('this.disabledDaysIndex', this.disabledDaysIndex)
    } else if (this.calenderAvilablity.availabilityHours === METTING_AVAILABILITY.anytime) {
      this.timeSlots = generateTimeSlotsWithAMPM(this.brandDetails?.features?.meeting_time_slot_difference_in_mins);
    }
  }


  onSubmit(): void {
    console.log("this.meetingForm.value", this.meetingForm.value)
    const payload = this.meetingForm.value
    const { date } = payload;

    payload.date = `${date.year}-${date.month}-${date.day}`


    const utcTimeFrom = moment(`${date.year}-${date.month}-${date.day} ${payload.timeFrom}`, 'YYYY-MM-DD HH:mm').utc()
    const sqlDateFrom = utcTimeFrom.utc().format("YYYY-MM-DD")
    const sqlTimeFrom = utcTimeFrom.utc().format("HH:mm")

    const utcTimeTo = moment(`${date.year}-${date.month}-${date.day} ${payload.timeTo}`, 'YYYY-MM-DD HH:mm').utc()
    const sqlDateTo = utcTimeTo.utc().format("YYYY-MM-DD")
    const sqlTimeTo = utcTimeTo.utc().format("HH:mm")

    console.log('sqlDateFrom', sqlDateFrom, 'sqlTimeFrom', sqlTimeFrom);
    console.log('sqlDateFrom', sqlDateTo, 'sqlTimeFrom', sqlTimeTo);


    this.uiLoader.start()
    if (this.isRescheduling) {
      const payloadNew = {
        date: sqlDateFrom,
        timeFrom: sqlTimeFrom,
        timeTo: sqlTimeTo,
      }
      this.meetingService.proposeTime(this.modalData.event.uuid, payloadNew).subscribe((response) => {
        if (response) {
          this.refreshData.emit();
          this.toastAlertService.showToast(response.message);
          this.close();
        }
        this.uiLoader.stop()
      }, err => this.uiLoader.stop())
    } else {
      const payloadNew = {
        ...this.meetingForm.value,
        date: sqlDateFrom,
        timeFrom: sqlTimeFrom,
        timeTo: sqlTimeTo,
        offset: '' + new Date().getTimezoneOffset(),
        timeZone: moment.tz.guess(),
        meetingTimeType: MeetingTime.schedule_later,
      }
      this.meetingService.updateMeeting(this.modalData.event.uuid, payloadNew).subscribe((response) => {
        if (response) {
          this.refreshData.emit();
          this.toastAlertService.showToast(response.message);
          this.close();
        }
        this.uiLoader.stop()
      }, err => this.uiLoader.stop())
    }
  }

  durationOptions = ['15','30','45','60']
  onSelectDuration(value) {
    this.meetingForm.get('duration').setValue(value)
    this.handleDurationChange()
  }
  handleDurationChange() {
    let today = new Date();
    const fromTime = this.meetingForm.value.timeFrom;


    // today.setHours(today.getHours() + (+event))
    today.setHours(fromTime.split(':')[0])
    today.setMinutes(fromTime.split(':')[1])
    today = add(today, {
      minutes: +this.meetingForm.value.duration
    })
    const endTime = today.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);


    this.meetingForm.patchValue({
      timeTo: endTime
    })
    console.log('event', this.meetingForm.value)
  }

  close() {
    this.modal.dismissAll();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
