<div class="d-flex flex-wrap mb-4 px-5 pt-2 rounded" style="background-color: #f3f6f9;">
  <span class="circle"></span>
  <h1 class="fs-2 text-dark fw-boldest ms-6">{{item.formTitle}}</h1>
</div>

<div>
  <div *ngFor="let section of item.sections" class="mb-8">
    <!-- <h1 class="fs-3 text-dark mb-5 fw-boldest ">{{section.sectionTitle}}</h1> -->
    <ng-container *ngIf="isSectionVisible(section)">
      <div *ngIf="!section.is_array" class="row">
        <ng-container *ngFor="let field of section.fields">
          <ng-container *ngIf="isFieldVisible(field)">
          <div class="mb-2 {{field.fieldSize}}" *ngIf="isFieldVisbile(field)">
            <div class="text-gray-800 fs-6">
              {{field.fieldTitle}}
            </div>
            <div class="d-flex align-items-start  pb-2 pt-3 flex-wrap flex-column">
              <ng-container *ngIf="
              isDataAvailable(field);
                else noData
              ">

                <div *ngIf="field.fieldType === 'radio_grid'">
                  <div *ngFor="let fieldData of field.data | keyvalue ">
                    <b>{{fieldData.key}}</b>: {{fieldData.value}}
                  </div>
                </div>
                <div *ngIf="field.fieldType === 'checkbox_grid'">
                  <div *ngFor="let fieldData of field.data | keyvalue ">
                    <b>{{fieldData.key}}</b>: {{fieldData.value}}
                  </div>
                </div>

                <div *ngIf="field.fieldType === 'phone_number_field'">
                  <span class="text-gray-800 fs-4 fw-bolder mb-0" >
                    {{field.data.internationalNumber}}
                  </span>
                </div>


                <div *ngIf="field.fieldType === 'date_picker'">
                  <span class="text-gray-800 fs-4 fw-bolder mb-0"  *ngIf="field.data">
                  {{formatDateField(field.data)}}
                  </span>
                </div>

                <div *ngIf="field.fieldType === 'geography_picker'">
                  <span class="text-gray-800 fs-4 fw-bolder mb-0"  *ngIf="field.data">
                  {{formatGeographyFields(field.data)}}
                  </span>
                </div>
                <div *ngIf="field.fieldType === 'text_input_grid'">
                  <span class="text-gray-800 fs-4 fw-bolder mb-0"  *ngIf="field.data" [innerHtml]="formatTextGridFields(field.data)">
                  </span>
                </div>
                <div *ngIf="field.fieldType === 'number_input_grid'">
                  <span class="text-gray-800 fs-4 fw-bolder mb-0"  *ngIf="field.data" [innerHtml]="formatNumberGridFields(field.data)">
                  </span>
                </div>

                <div *ngIf="field.fieldType === 'number_input_grid'">
                  <span class="text-gray-800 fs-4 fw-bolder mb-0"  *ngIf="field.data" [innerHtml]="formatNumberGridFields(field.data)">
                  </span>
                </div>


                <div *ngIf="field.fieldType === 'image_upload'">
                  <span class="text-gray-800 fs-4 fw-bolder mb-0"  *ngIf="field.data">
                    <img [src]="field.data | imagekitUrlAppender" style="max-width: 200px;" class="border shadow-sm"/>
                  </span>
                </div>

                <span class="text-gray-800 fs-4 fw-bolder mb-0" [innerHTML]="field.data"
                *ngIf="!isFile(field) &&
                    field.fieldType!=='url_field' &&
                    field.fieldType!=='radio_grid'&&
                    field.fieldType!=='checkbox_grid' &&
                    field.fieldType !== 'phone_number_field' &&
                    field.fieldType !== 'geography_picker' &&
                    field.fieldType !== 'text_input_grid' &&
                    field.fieldType !== 'number_input_grid' &&
                    field.fieldType !== 'image_upload' &&
                    field.fieldType !== 'date_picker'">
                </span>
                <a class="text-gray-800 fs-4 fw-bolder mb-0" [innerHTML]="field.data" *ngIf="field.fieldType==='url_field'" [href]="field.data" target="_blank">
                </a>

                <div class="btn btn-theme text-white ls-2 text-uppercase fw-boldest btn-sm" [ngStyle]="{color: 'white'}"
                *ngIf="isFile(field)"
                (click)="downloadFile(field.data)"
                >
                <span style="color: white !important;">
                  Download
                </span>
              </div>
              </ng-container>
            </div>
            <div class="separator separator-dashed  mb-5"></div>
          </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="table-responsive" *ngIf="section.is_array">
        <table class="table align-middle table-row-dashed gy-5 dataTable no-footer" id="kt_table_users">
          <thead>
            <tr class="fw-bolder fs-7 gs-0">
              <th scope="col" *ngFor="let field of section.fields">{{field.fieldTitle}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of section.data">
              <th scope="row" *ngFor="let field of section.fields">
                <span [innerHTML]="data[field.fieldCode]" *ngIf="!isImg(data, field) && !isFile(field)">
                </span>
                <ng-container *ngIf="isFile(field) && !isImg(data, field)">
                  <a class="btn btn-theme text-white ls-2 text-uppercase fw-boldest btn-sm" [ngStyle]="{color: 'white'}"
                    [href]="data[field.fieldCode] | imagekitUrlAppender" target="_blank">
                    <span style="color: white !important;">
                      Download
                    </span>
                  </a>
                </ng-container>

                <ng-container *ngIf="isImg(data, field)">
                  <lightgallery [settings]="settings">
                    <ng-container *ngFor="let image of data[field.fieldCode]">

                      <a [href]="image| imagekitUrlAppender">

                        <img alt="" class="ng-lazyloaded mt-2" [useSrcset]="true"
                          [lazyLoad]="image| imagekitUrlAppender" style="height: 75px; padding: 8px;">
                      </a>

                    </ng-container>
                  </lightgallery>
                </ng-container>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>

  </div>

</div>


<ng-template #noData>
  <span class="fs-4 fw-bolder">-</span>
</ng-template>
