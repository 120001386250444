<div class="modal-content">

  <div class="modal-header border-0 justify-content-end">

    <div class="btn btn-icon btn-sm btn-color-gray-500 btn-active-icon-primary" (click)="closeModal()"
      data-bs-toggle="tooltip" title="" data-bs-dismiss="kt_modal_view_event_edit" data-bs-original-title="Hide Event">

      <span class="svg-icon svg-icon-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
            fill="currentColor"></rect>
          <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor">
          </rect>
        </svg>
      </span>

    </div>

  </div>

  <div class="modal-body pt-0 pb-10 px-lg-17">
    <div class="row ">
      <div class="col-md-6 col-12">
        <div class="d-flex">

          <div class="position-relative me-5">
            <p class="calendar mb-0 pb-0">{{modalData?.event?.dateFormatted}} <em>{{modalData?.event?.month}}</em></p>
          </div>


          <div class="mb-9">

            <input type="text" style="display:none" />
            <div class="d-flex align-items-center mb-2">
              <span class="fs-3 fw-bolder me-3" data-kt-calendar="event_name">{{modalData?.event?.eventTitle}}</span>
            </div>


            <!-- <div class="fs-6" data-kt-calendar="event_description">{{modalData?.event?.date | date :'MMM d, y'}}
            </div> -->


            <div class="d-flex align-items-center ">
              <div class="fs-4 no-wrap" data-kt-calendar="event_description" *ngIf="modalData?.event?.dates?.length<=1">{{modalData?.event?.date | date
                :'MMM d, y'}}
              </div>

              <div class="fs-4 no-wrap" data-kt-calendar="event_description" *ngIf="modalData?.event.dates?.length>1">{{modalData.event?.dates[0]?.date
                | date :'MMM d, y'}} -
                {{modalData?.event?.dates[modalData?.event?.dates?.length-1]?.date | date :'MMM d, y'}}
              </div>

              <!-- <div class="d-flex align-items-center ms-5 no-wrap" *ngIf="modalData.event.dates?.length<=1">

                <span class="svg-icon svg-icon-1 svg-icon-danger me-3">
                  <i class="bi  bi-clock fs-2 header-icons-font-size"></i>
                </span>
                <div class="fs-6 no-wrap">
                  <span class="fw-bolder me-2">Time</span>
                  <span data-kt-calendar="event_start_date">{{ modalData.event?.timeFrom | twentyFourHoursToAmPm}} - {{
                    modalData.event?.timeTo | twentyFourHoursToAmPm }}</span>
                </div>
              </div> -->
            </div>


            <div>
              <span class="badge badge-secondary badge-sm me-3" *ngIf="modalData?.event?.eventType">
                {{formatEnumStringForUI(modalData?.event?.eventType) === 'Webinar' ?
                'Seminar/Webinar':formatEnumStringForUI(modalData?.event?.eventType) }}
              </span>
              <span class="badge badge-secondary badge-sm me-3" *ngIf="modalData?.event?.deliveryMode">
                {{formatEnumStringForUI(modalData?.event?.deliveryMode)}}
              </span>

            </div>

          </div>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="w-100 mb-5 " *ngIf="modalData.event?.headerImage&&globalSettings">
          <!-- <img [src]="modalData.event.headerImage | imagekitUrlAppender" class="rounded shadow-sm w-100 event-image" /> -->

          <lightgallery [settings]="settings">
            <!-- <ng-container *ngFor="let image of data[field.fieldCode]"> -->

              <a [href]="modalData.event?.headerImage| imagekitUrlAppender">

                <img [src]="modalData.event.headerImage | imagekitUrlAppender" class="rounded shadow-sm w-100 event-image" />
              </a>

            <!-- </ng-container> -->
          </lightgallery>

        </div>



      </div>
    </div>
    <div class="separator separator-dashed mt-2 mb-4"></div>

<div class="row " *ngIf="!showChildComponent">
  <div class="col-md-6 col-12">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <div class="d-flex align-items-start mb-2">

          <span class="svg-icon svg-icon-1 svg-icon-danger me-5">
            <i class="bi  bi-clock fs-2 header-icons-font-size"></i>
          </span>
          <div class="fs-6">
            <span class="fw-bolder me-2">Time</span>
            <div data-kt-calendar="event_start_date">{{ modalData?.event?.timeFrom}} - {{
              modalData?.event?.timeTo }}</div>
          </div>
        </div>

        <!-- <div class="d-flex align-items-center " *ngIf="modalData?.event?.speakerName">
          <span class="svg-icon svg-icon-1 svg-icon-danger me-5">
            <i class="bi bi-person-fill fs-1 header-icons-font-size"></i>
          </span>
          <div class="fs-6">
            <span class="fw-bolder me-2">Speaker</span>
            <span data-kt-calendar="event_end_date">
              <a [href]="modalData?.event.speakerLinkedin" target="_blank" class="text-black"
                [ngbTooltip]="modalData?.event?.speakerBio">
                {{ modalData?.event?.speakerName}}
              </a></span>
          </div>

        </div> -->

        <!-- <div class="d-flex align-items-center " *ngIf="modalData?.event?.speakerDetails">
          <span class="svg-icon svg-icon-1 svg-icon-danger me-5">
            <i class="bi bi-person-fill fs-1 header-icons-font-size"></i>
          </span>
          <div class="fs-6">
            <span class="fw-bolder me-2">Speaker</span>
            <span data-kt-calendar="event_end_date text-black">
              {{ modalData?.event?.speakerDetails?.name}}

              <a *ngIf="modalData?.event?.speakerType == 'registered'" [href]="speakerProfileLink" target="_blank" class="text-primary">
                View profile
              </a>

              <a *ngIf="modalData?.event?.speakerType == 'not_registered' && modalData?.event?.speakerLinkedin" [href]="modalData?.event?.speakerLinkedin" target="_blank" class="text-primary">
                Go to Linkedin
              </a>

            </span>
          </div>

        </div> -->

        <div class="d-flex align-items-start mt-3" *ngIf="modalData?.event?.eventRegistrationUrl && modalData?.event?.deliveryMode == 'online'">
          <span class="svg-icon svg-icon-1 svg-icon-danger me-5">
            <i class="bi bi-link-45deg fs-1 header-icons-font-size"></i>
          </span>
          <div class="fs-6 d-flex flex-column">
            <span class="fw-bolder me-2">External Meeting Url</span>
            <div data-kt-calendar="event_end_date">
              <a [href]="modalData?.event.eventRegistrationUrl" target="_blank"
                class="text-primary d-block text-truncate" style="width: 325px;">
                {{ modalData?.event?.eventRegistrationUrl}}
              </a>
            </div>
          </div>
        </div>




        <div class="d-flex align-items-start mt-3" *ngIf="modalData?.event?.location && modalData?.event?.deliveryMode == 'in_person'">
          <span class="svg-icon svg-icon-1 svg-icon-danger me-5">
            <i class="bi bi-link-45deg fs-1 header-icons-font-size"></i>
          </span>
          <div class="fs-6 d-flex flex-column">
            <span class="fw-bolder me-2">Venue</span>
            <div data-kt-calendar="event_end_date">
              <p class="text-black d-block text-truncate" style="width: 325px;">
                {{ modalData?.event?.location}}
              </p>
            </div>
          </div>
        </div>


      </div>

    </div>


    <div class="d-flex justify-content-between align-items-center pt-6 flex-column alert alert-danger mt-5"
      *ngIf="!isInPast">

      <ng-container  *ngIf="!existingAttendence || existingAttendence?.responseAction==='tentative' " >
        <p class="fs-3 text-black fw-bold">
          Are you attending?
        </p>
        <div class="">
          <span *ngFor="let item of globalSettings?.platformEventResponseActions" (click)="selectResponse(item)"
            class="badge badge-pill rounded-30 px-5 py-3 cursor-pointer me-3 fs-6 "
            [ngClass]="item.value === selectedResponse?.value ?  'badge-primary': 'badge-dark'">
            {{item.name}}</span>
        </div>
      </ng-container>





      <ng-container *ngIf="existingAttendence?.responseAction==='attending'">
        <p class="fs-3 text-black mb-0">
          You are attending this event
        </p>
      </ng-container>

      <ng-container *ngIf="existingAttendence?.responseAction==='declined'">
        <p class="fs-3 text-black">
          You have opted not to attend this event.
        </p>
      </ng-container>

      <div *ngIf="modalData?.event?.date && selectedResponse?.value === 'attending'" class="mt-4">
        <add-to-calendar-button [attr.name]="modalData?.event?.eventTitle"
          [attr.description]="modalData?.event?.eventDescription" [attr.location]="modalData?.event?.rsvpUrl"
          timeZone="Asia/Calcutta" location="World Wide Web"
          options="'Apple','Google','iCal','Yahoo','Microsoft365','MicrosoftTeams','Outlook.com'" style="width: 100%;"
          [attr.startTime]="formatTo24Hour(modalData?.event?.timeFrom)"
          [attr.endTime]="formatTo24Hour(modalData?.event?.timeTo)" [attr.startDate]="modalData.event.date"
          trigger="click" label="Add to calender"></add-to-calendar-button>
      </div>


    </div>

    <div *ngIf="isInPast" class="my-5">
      <div class="alert alert-danger p-4">
        The event has already passed or has been closed for registrations.
      </div>
    </div>

    <div class="mt-5" *ngIf="modalData?.event?.speakers?.length">
      <p class="fw-bolder fs-4">Speakers</p>

      <div class="row">
        <div class="col-6 cursor-pointer" *ngIf="modalData.event?.speakerDetails" (click)="handleMainSpeakerClick()">
          <div class="d-flex flex-column align-items-center justify-content-center border  rounded py-4">

            <ng-container *ngIf="mainSpeaker?.speaker_image; else nameAvatar">
              <img [src]="(mainSpeaker?.speaker_image |imagekitUrlAppender)+ '?tr=w-60,h-60'" alt="Profile image"
                class=" bg-white rounded-circle" />
            </ng-container>
            <ng-template #nameAvatar>
              <ngx-avatars [name]="mainSpeaker?.name" size="60"> </ngx-avatars>
            </ng-template>


            <p class="fw-bold fs-6 mt-2 mb-1">
              {{mainSpeaker.name}}
            </p>
            <div class="d-flex align-items-center gap-5">
              <span class="bi bi-info-square-fill square-fill-info-custom socialIcon fs-3  ms-2 " *ngIf="mainSpeaker?.bio"
              [ngbTooltip]="mainSpeaker.bio" container="body" placement="bottom"></span>
              <a  target="_blank" [href]="mainSpeaker?.linkedin" class="wow fadeInLeft" *ngIf="mainSpeaker?.linkedin" >
                <i class="bi bi-linkedin socialIcon"></i>
                </a>
            </div>
          </div>
        </div>


      <div class="col-6" *ngFor="let speaker of modalData.event.speakers">
        <div class="d-flex flex-column align-items-center justify-content-center border  rounded py-4">

          <ng-container *ngIf="speaker?.speaker_image; else nameAvatar">
            <img [src]="(speaker?.speaker_image |imagekitUrlAppender)+ '?tr=w-60,h-60'" alt="Profile image"
              class=" bg-white rounded-circle" />
          </ng-container>
          <ng-template #nameAvatar>
            <ngx-avatars [name]="speaker?.name" size="60"> </ngx-avatars>
          </ng-template>


          <p class="fw-bold fs-6 mt-2 mb-1">
            {{speaker.name}}
          </p>
          <div *ngIf="speaker?.linkedin" class="d-flex align-items-center gap-5">
            <span class="bi bi-info-square-fill square-fill-info-custom socialIcon fs-3  ms-2 "
            [ngbTooltip]="speaker.bio" container="body" placement="bottom"></span>
            <a  target="_blank" [href]="speaker?.linkedin" class="wow fadeInLeft">
              <i class="bi bi-linkedin socialIcon"></i>
              </a>
          </div>
        </div>

      </div>
      </div>

    </div>
  </div>
  <div class="col-md-6 col-12">
    <div class="resume-banner2 mt-8">
      <span class="fw-bolder me-2 mb-3 fs-3 text-black">Description</span>
      <div class="separator separator-custom separator-dashed mt-2 mb-4"></div>
      <div *ngIf="modalData?.event?.eventDescription" [innerHTML]="modalData?.event?.eventDescription"
      style="word-wrap: break-word;"
      >
      </div>

      <div *ngFor="let day of modalData?.event?.dates" class="mt-4">
        <div class="mb-0" *ngIf="day.breaks?.length">
          <strong>{{ day.date  | date :'MMM d, y'}}:</strong>
          <br />
          <div *ngFor="let breakSlot of day.breaks; let isLast = last" class="ms-4">
            {{ breakSlot.title }}
            (<span >
              {{ breakSlot.start_time | twentyFourHoursToAmPm}} - {{ breakSlot.end_time | twentyFourHoursToAmPm}}
              <!-- <span *ngIf="!isInnerLast">, </span> -->
            </span>)

            <!-- (<span *ngFor="let breakTime of breakSlot.slots; let isInnerLast = last">
              {{ breakTime.timeFrom | twentyFourHoursToAmPm}} - {{ breakTime.timeTo | twentyFourHoursToAmPm}}
              <span *ngIf="!isInnerLast">, </span>
            </span>) -->
            <!-- <span *ngIf="!isLast">, </span> -->
            <br />
          </div>
        </div>
      </div>


      <!-- <div *ngFor="let day of modalData?.event?.slots" class="mt-4">
        <div class="mb-0" *ngIf="day.breakSlots?.length">
          <strong>{{ day.date  | date :'MMM d, y'}}:</strong>
          <br />
          <div *ngFor="let breakSlot of day.breakSlots; let isLast = last" class="ms-4">
            {{ breakSlot.title }}
            (<span *ngFor="let breakTime of breakSlot.slots; let isInnerLast = last">
              {{ breakTime.timeFrom | twentyFourHoursToAmPm}} - {{ breakTime.timeTo | twentyFourHoursToAmPm}}
              <span *ngIf="!isInnerLast">, </span>
            </span>)
            <br />
          </div>
        </div>
      </div> -->



      <div *ngIf="!modalData?.event?.eventDescription">
        No Notes found
      </div>

    </div>
  </div>
</div>

<!-- For Event Quetion -->
<div class="row ">
  <div class="col-md-12 col-12">
    <app-attend-information
    [eventId]="eventUUID"
     [eventQuestionList]="eventQuestionsList"
    [existingAttendence]="existingAttendenceForChild"
    *ngIf="showChildComponent"
    (closeComponent)="closeChild()"
    (modalDataChange)="updateModalData($event)"
    (bookingResponseData)="updateExistingAttendence($event)"
    [attendingType]="attendingType">
    
  </app-attend-information>
  </div>

</div>

  </div>

</div>
